module.exports = {
    Cc: require('./Cc'),
    Zs: require('./Zs'),
    Po: require('./Po'),
    Sc: require('./Sc'),
    Ps: require('./Ps'),
    Pe: require('./Pe'),
    Sm: require('./Sm'),
    Pd: require('./Pd'),
    Nd: require('./Nd'),
    Lu: require('./Lu'),
    Sk: require('./Sk'),
    Pc: require('./Pc'),
    Ll: require('./Ll'),
    So: require('./So'),
    Lo: require('./Lo'),
    Pi: require('./Pi'),
    Cf: require('./Cf'),
    No: require('./No'),
    Pf: require('./Pf'),
    Lt: require('./Lt'),
    Lm: require('./Lm'),
    Mn: require('./Mn'),
    Me: require('./Me'),
    Mc: require('./Mc'),
    Nl: require('./Nl'),
    Zl: require('./Zl'),
    Zp: require('./Zp'),
    Cs: require('./Cs'),
    Co: require('./Co')
};
/*
 * Generated by PEG.js 0.10.0.
 *
 * http://pegjs.org/
 */

"use strict";

function peg$subclass(child, parent) {
  function ctor() { this.constructor = child; }
  ctor.prototype = parent.prototype;
  child.prototype = new ctor();
}

function peg$SyntaxError(message, expected, found, location) {
  this.message  = message;
  this.expected = expected;
  this.found    = found;
  this.location = location;
  this.name     = "SyntaxError";

  if (typeof Error.captureStackTrace === "function") {
    Error.captureStackTrace(this, peg$SyntaxError);
  }
}

peg$subclass(peg$SyntaxError, Error);

peg$SyntaxError.buildMessage = function(expected, found) {
  var DESCRIBE_EXPECTATION_FNS = {
        literal: function(expectation) {
          return "\"" + literalEscape(expectation.text) + "\"";
        },

        "class": function(expectation) {
          var escapedParts = "",
              i;

          for (i = 0; i < expectation.parts.length; i++) {
            escapedParts += expectation.parts[i] instanceof Array
              ? classEscape(expectation.parts[i][0]) + "-" + classEscape(expectation.parts[i][1])
              : classEscape(expectation.parts[i]);
          }

          return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
        },

        any: function(expectation) {
          return "any character";
        },

        end: function(expectation) {
          return "end of input";
        },

        other: function(expectation) {
          return expectation.description;
        }
      };

  function hex(ch) {
    return ch.charCodeAt(0).toString(16).toUpperCase();
  }

  function literalEscape(s) {
    return s
      .replace(/\\/g, '\\\\')
      .replace(/"/g,  '\\"')
      .replace(/\0/g, '\\0')
      .replace(/\t/g, '\\t')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
  }

  function classEscape(s) {
    return s
      .replace(/\\/g, '\\\\')
      .replace(/\]/g, '\\]')
      .replace(/\^/g, '\\^')
      .replace(/-/g,  '\\-')
      .replace(/\0/g, '\\0')
      .replace(/\t/g, '\\t')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
  }

  function describeExpectation(expectation) {
    return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
  }

  function describeExpected(expected) {
    var descriptions = new Array(expected.length),
        i, j;

    for (i = 0; i < expected.length; i++) {
      descriptions[i] = describeExpectation(expected[i]);
    }

    descriptions.sort();

    if (descriptions.length > 0) {
      for (i = 1, j = 1; i < descriptions.length; i++) {
        if (descriptions[i - 1] !== descriptions[i]) {
          descriptions[j] = descriptions[i];
          j++;
        }
      }
      descriptions.length = j;
    }

    switch (descriptions.length) {
      case 1:
        return descriptions[0];

      case 2:
        return descriptions[0] + " or " + descriptions[1];

      default:
        return descriptions.slice(0, -1).join(", ")
          + ", or "
          + descriptions[descriptions.length - 1];
    }
  }

  function describeFound(found) {
    return found ? "\"" + literalEscape(found) + "\"" : "end of input";
  }

  return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
};

function peg$DefaultTracer() {
  this.indentLevel = 0;
}

peg$DefaultTracer.prototype.trace = function(event) {
  var that = this;

  function log(event) {
    function repeat(string, n) {
       var result = "", i;

       for (i = 0; i < n; i++) {
         result += string;
       }

       return result;
    }

    function pad(string, length) {
      return string + repeat(" ", length - string.length);
    }

    if (typeof console === "object") {
      console.log(
        event.location.start.line + ":" + event.location.start.column + "-"
          + event.location.end.line + ":" + event.location.end.column + " "
          + pad(event.type, 10) + " "
          + repeat("  ", that.indentLevel) + event.rule
      );
    }
  }

  switch (event.type) {
    case "rule.enter":
      log(event);
      this.indentLevel++;
      break;

    case "rule.match":
      this.indentLevel--;
      log(event);
      break;

    case "rule.fail":
      this.indentLevel--;
      log(event);
      break;

    default:
      throw new Error("Invalid event type: " + event.type + ".");
  }
};

function peg$parse(input, options) {
  options = options !== void 0 ? options : {};

  var peg$FAILED = {},

      peg$startRuleFunctions = { Root: peg$parseRoot, Preamble: peg$parsePreamble },
      peg$startRuleFunction  = peg$parseRoot,

      peg$c0 = function(x) { 
          const comment = commentMap ? Array.from(commentMap.values()) : undefined;
          return { kind: "ast.root", content: x, comment };
        },
      peg$c1 = "begin{document}",
      peg$c2 = peg$literalExpectation("begin{document}", false),
      peg$c3 = function(e) { return e; },
      peg$c4 = peg$anyExpectation(),
      peg$c5 = function(x, rest) {
          const comment = commentMap ? Array.from(commentMap.values()) : undefined;
          return { kind: "ast.preamble", content: x, rest, comment };
        },
      peg$c6 = function(x) { 
          return x;
        },
      peg$c7 = function(c) {
          timeKeeper && timeKeeper.check();
          return { kind: "text.string", content: c, location: location() };
        },
      peg$c8 = function(c) {
          if (options.enableMathCharacterLocation) {
            return { kind: "math.character", content: c, location: location() };
          } else {
            return { kind: "math.character", content: c };
          }
        },
      peg$c9 = "%",
      peg$c10 = peg$literalExpectation("%", false),
      peg$c11 = ".",
      peg$c12 = peg$literalExpectation(".", false),
      peg$c13 = peg$otherExpectation("special command"),
      peg$c14 = "label",
      peg$c15 = peg$literalExpectation("label", false),
      peg$c16 = "ref",
      peg$c17 = peg$literalExpectation("ref", false),
      peg$c18 = "eqref",
      peg$c19 = peg$literalExpectation("eqref", false),
      peg$c20 = "autoref",
      peg$c21 = peg$literalExpectation("autoref", false),
      peg$c22 = function(name, label) {
          return { kind: "command.label", name, label: label.join(''), location: location() };
        },
      peg$c23 = "}",
      peg$c24 = peg$literalExpectation("}", false),
      peg$c25 = "{",
      peg$c26 = peg$literalExpectation("{", false),
      peg$c27 = "url",
      peg$c28 = peg$literalExpectation("url", false),
      peg$c29 = function(x) {
          return { kind: "command.url", name: "url", url: x, location: location() };
        },
      peg$c30 = "href",
      peg$c31 = peg$literalExpectation("href", false),
      peg$c32 = function(arg, x, grp) {
          return { kind: "command.href", name: "href", url: x, content: grp.content, arg: arg || undefined, location: location() };
        },
      peg$c33 = "verb*",
      peg$c34 = peg$literalExpectation("verb*", false),
      peg$c35 = "verb",
      peg$c36 = peg$literalExpectation("verb", false),
      peg$c37 = function(name, e, end) {return end === e;},
      peg$c38 = function(name, e, x, end) {return end === e;},
      peg$c39 = function(name, e, x) {
          return { kind: "verb", name, escape: e, content: x, location: location() };
        },
      peg$c40 = function(x) {
          return x;
        },
      peg$c41 = "verbatim",
      peg$c42 = peg$literalExpectation("verbatim", false),
      peg$c43 = function(x) {
          return { kind: "env.verbatim", name: "verbatim", content: x, location: location() };
        },
      peg$c44 = "verbatim*",
      peg$c45 = peg$literalExpectation("verbatim*", false),
      peg$c46 = function(x) {
          return { kind: "env.verbatim", name: "verbatim*", content: x, location: location() };
        },
      peg$c47 = "minted",
      peg$c48 = peg$literalExpectation("minted", false),
      peg$c49 = function(args, x) {
          return { kind: "env.minted", name: "minted", args: args, content: x, location: location() };
        },
      peg$c50 = "lstlisting",
      peg$c51 = peg$literalExpectation("lstlisting", false),
      peg$c52 = function(arg, x) {
          return { kind: "env.lstlisting", name: "lstlisting", arg: arg, content: x, location: location() };
        },
      peg$c53 = "comment",
      peg$c54 = peg$literalExpectation("comment", false),
      peg$c55 = function(x) {
          return { kind: "env.comment", content: x, location: location() };
        },
      peg$c56 = function(t) {return t;},
      peg$c57 = function(eq) {
          return { kind: "inlineMath", content: eq, location: location() };
        },
      peg$c58 = function(x) {return x;},
      peg$c59 = function(x) {
          return { kind: "inlineMath", content: x, location: location() };
        },
      peg$c60 = function(x) {
          return { kind: "displayMath", content: x, location: location() };
        },
      peg$c61 = function(n, args) {
          return { kind: "command", name: n, args: args, location: location() };
        },
      peg$c62 = function(n) {
          return { kind: "command", name: n, args: [], location: location() };
        },
      peg$c63 = "@",
      peg$c64 = peg$literalExpectation("@", false),
      peg$c65 = "*",
      peg$c66 = peg$literalExpectation("*", false),
      peg$c67 = function(n) { return n + '*'; },
      peg$c68 = "\\*",
      peg$c69 = peg$literalExpectation("\\*", false),
      peg$c70 = /^[ $%#&{}_\-,\/@]/,
      peg$c71 = peg$classExpectation([" ", "$", "%", "#", "&", "{", "}", "_", "-", ",", "/", "@"], false, false),
      peg$c72 = function(c) {
          return { kind: "command", name: c, args: [], location: location() };
        },
      peg$c73 = "end",
      peg$c74 = peg$literalExpectation("end", false),
      peg$c75 = /^[^a-zA-Z]/,
      peg$c76 = peg$classExpectation([["a", "z"], ["A", "Z"]], true, false),
      peg$c77 = "[",
      peg$c78 = peg$literalExpectation("[", false),
      peg$c79 = "]",
      peg$c80 = peg$literalExpectation("]", false),
      peg$c81 = "(",
      peg$c82 = peg$literalExpectation("(", false),
      peg$c83 = ")",
      peg$c84 = peg$literalExpectation(")", false),
      peg$c85 = "def",
      peg$c86 = peg$literalExpectation("def", false),
      peg$c87 = function(token, numArgs, grArg) {
          return { kind: "command.def", token, args: numArgs.concat([grArg]), location: location() };
        },
      peg$c88 = function(c) {return c;},
      peg$c89 = function(x) {
          return { kind: "arg.group", content: x, location: location() };
        },
      peg$c90 = "left",
      peg$c91 = peg$literalExpectation("left", false),
      peg$c92 = "right",
      peg$c93 = peg$literalExpectation("right", false),
      peg$c94 = function(l, c) {return c;},
      peg$c95 = function(l, x, r) {
          return { kind: "math.matching_delimiters", left: l, right: r, content: x, location: location() };
        },
      peg$c96 = /^[()[\]|\/.]/,
      peg$c97 = peg$classExpectation(["(", ")", "[", "]", "|", "/", "."], false, false),
      peg$c98 = /^[{}]/,
      peg$c99 = peg$classExpectation(["{", "}"], false, false),
      peg$c100 = function(l, x, r) {
          return { kind: "math.math_delimiters", lcommand: l, rcommand: r, left: "(", right: ")", content: x, location: location() };
        },
      peg$c101 = function(l, x, r) {
          return { kind: "math.math_delimiters", lcommand: l, rcommand: r, left: "[", right: "]", content: x, location: location() };
        },
      peg$c102 = "\\{",
      peg$c103 = peg$literalExpectation("\\{", false),
      peg$c104 = "\\}",
      peg$c105 = peg$literalExpectation("\\}", false),
      peg$c106 = function(l, x, r) {
          return { kind: "math.math_delimiters", lcommand: l, rcommand: r, left: "\\{", right: "\\}", content: x, location: location() };
        },
      peg$c107 = "bigg",
      peg$c108 = peg$literalExpectation("bigg", false),
      peg$c109 = "Bigg",
      peg$c110 = peg$literalExpectation("Bigg", false),
      peg$c111 = "big",
      peg$c112 = peg$literalExpectation("big", false),
      peg$c113 = "Big",
      peg$c114 = peg$literalExpectation("Big", false),
      peg$c115 = /^[rlm]/,
      peg$c116 = peg$classExpectation(["r", "l", "m"], false, false),
      peg$c117 = function(body) {
          return { kind: "arg.optional", content: body, location: location() };
        },
      peg$c118 = function() { return { kind:"parbreak", location: location() }; },
      peg$c119 = ",",
      peg$c120 = peg$literalExpectation(",", false),
      peg$c121 = function(c) { return { kind: "text.string", content: c, location: location() }; },
      peg$c122 = function() {
          return { kind: "text.string", content: ",", location: location() };
        },
      peg$c123 = function(name, args, x) {return x;},
      peg$c124 = function(name, args, body, n) { return name === n; },
      peg$c125 = function(name, args, body, n) {
          return { kind: "env", name, args, content: body, location: location() };
        },
      peg$c126 = function(name, x) {return x;},
      peg$c127 = function(name, body, n) { return name === n; },
      peg$c128 = function(name, body, n) {
          return { kind: "env.math.align", name, args: [], content: body, location: location() };
        },
      peg$c129 = function(name, body, n) {
          return { kind: "env.math.aligned", name, args: [], content: body, location: location() };
        },
      peg$c130 = "text",
      peg$c131 = peg$literalExpectation("text", false),
      peg$c132 = function(arg) {
          return { kind: "command.text", arg: arg, location: location() };
        },
      peg$c133 = function(x) { return { kind: "comment", content: x, location: location() }; },
      peg$c134 = "begin",
      peg$c135 = peg$literalExpectation("begin", false),
      peg$c136 = "equation*",
      peg$c137 = peg$literalExpectation("equation*", false),
      peg$c138 = "equation",
      peg$c139 = peg$literalExpectation("equation", false),
      peg$c140 = "align*",
      peg$c141 = peg$literalExpectation("align*", false),
      peg$c142 = "align",
      peg$c143 = peg$literalExpectation("align", false),
      peg$c144 = "alignat*",
      peg$c145 = peg$literalExpectation("alignat*", false),
      peg$c146 = "alignat",
      peg$c147 = peg$literalExpectation("alignat", false),
      peg$c148 = "gather*",
      peg$c149 = peg$literalExpectation("gather*", false),
      peg$c150 = "gather",
      peg$c151 = peg$literalExpectation("gather", false),
      peg$c152 = "multline*",
      peg$c153 = peg$literalExpectation("multline*", false),
      peg$c154 = "multline",
      peg$c155 = peg$literalExpectation("multline", false),
      peg$c156 = "flalign*",
      peg$c157 = peg$literalExpectation("flalign*", false),
      peg$c158 = "flalign",
      peg$c159 = peg$literalExpectation("flalign", false),
      peg$c160 = "math",
      peg$c161 = peg$literalExpectation("math", false),
      peg$c162 = "displaymath",
      peg$c163 = peg$literalExpectation("displaymath", false),
      peg$c164 = "alignedat",
      peg$c165 = peg$literalExpectation("alignedat", false),
      peg$c166 = "aligned",
      peg$c167 = peg$literalExpectation("aligned", false),
      peg$c168 = "cases*",
      peg$c169 = peg$literalExpectation("cases*", false),
      peg$c170 = "cases",
      peg$c171 = peg$literalExpectation("cases", false),
      peg$c172 = "gathered",
      peg$c173 = peg$literalExpectation("gathered", false),
      peg$c174 = "split",
      peg$c175 = peg$literalExpectation("split", false),
      peg$c176 = "matrix",
      peg$c177 = peg$literalExpectation("matrix", false),
      peg$c178 = "bmatrix",
      peg$c179 = peg$literalExpectation("bmatrix", false),
      peg$c180 = "pmatrix",
      peg$c181 = peg$literalExpectation("pmatrix", false),
      peg$c182 = "vmatrix",
      peg$c183 = peg$literalExpectation("vmatrix", false),
      peg$c184 = "Bmatrix",
      peg$c185 = peg$literalExpectation("Bmatrix", false),
      peg$c186 = "Vmatrix",
      peg$c187 = peg$literalExpectation("Vmatrix", false),
      peg$c188 = "\\",
      peg$c189 = peg$literalExpectation("\\", false),
      peg$c190 = "$",
      peg$c191 = peg$literalExpectation("$", false),
      peg$c192 = "&",
      peg$c193 = peg$literalExpectation("&", false),
      peg$c194 = function() {
          return { kind: "alignmentTab", location: location() };
        },
      peg$c195 = "#",
      peg$c196 = peg$literalExpectation("#", false),
      peg$c197 = function(x) {
          return { kind: "superscript", arg: x, location: location() };
        },
      peg$c198 = "^",
      peg$c199 = peg$literalExpectation("^", false),
      peg$c200 = function() {
          return { kind: "superscript", arg: undefined, location: location() };
        },
      peg$c201 = function(x) {
          return { kind: "subscript", arg: x, location: location() };
        },
      peg$c202 = "_",
      peg$c203 = peg$literalExpectation("_", false),
      peg$c204 = function() {
          return { kind: "subscript", arg: undefined, location: location() };
        },
      peg$c205 = "\0",
      peg$c206 = peg$literalExpectation("\0", false),
      peg$c207 = function() {
          return { kind: "ignore", location: location() };
        },
      peg$c208 = /^[a-zA-Z]/,
      peg$c209 = peg$classExpectation([["a", "z"], ["A", "Z"]], false, false),
      peg$c210 = /^[0-9]/,
      peg$c211 = peg$classExpectation([["0", "9"]], false, false),
      peg$c212 = /^[.,;:\-*\/()!?=+<>[\]]/,
      peg$c213 = peg$classExpectation([".", ",", ";", ":", "-", "*", "/", "(", ")", "!", "?", "=", "+", "<", ">", "[", "]"], false, false),
      peg$c214 = "~",
      peg$c215 = peg$literalExpectation("~", false),
      peg$c216 = function() {
          return { kind: "activeCharacter", location: location() };
        },
      peg$c217 = function(n) {
          return { kind: "commandParameter", nargs: n, location: location() };
        },
      peg$c218 = "document",
      peg$c219 = peg$literalExpectation("document", false),
      peg$c220 = function(c) {
          return c;
        },
      peg$c221 = "linebreak",
      peg$c222 = peg$literalExpectation("linebreak", false),
      peg$c223 = function(n, arg) {
          return { kind: "linebreak", name: n, arg: arg || undefined, location: location() };
        },
      peg$c224 = "newline*",
      peg$c225 = peg$literalExpectation("newline*", false),
      peg$c226 = "newline",
      peg$c227 = peg$literalExpectation("newline", false),
      peg$c228 = function(n) {
          return { kind: "linebreak", name: n, arg: undefined, location: location() }
        },
      peg$c229 = function() {
          return { kind: "softbreak" };
        },
      peg$c230 = function() {
          return { kind: "space" };
        },
      peg$c231 = "\n",
      peg$c232 = peg$literalExpectation("\n", false),
      peg$c233 = "\r\n",
      peg$c234 = peg$literalExpectation("\r\n", false),
      peg$c235 = "\r",
      peg$c236 = peg$literalExpectation("\r", false),
      peg$c237 = "\u2028",
      peg$c238 = peg$literalExpectation("\u2028", false),
      peg$c239 = "\u2029",
      peg$c240 = peg$literalExpectation("\u2029", false),
      peg$c241 = /^[ \t]/,
      peg$c242 = peg$classExpectation([" ", "\t"], false, false),
      peg$c243 = peg$otherExpectation("spaces"),
      peg$c244 = function(c) { 
          if (options.enableComment) {
            const loc = location();
            const locJson = JSON.stringify(loc);
            if (!commentMap.has(locJson)) {
              commentMap.set(locJson, { kind: "comment", content: c, location: loc } );
            }
          }
        },
      peg$c245 = function() {
          return undefined;
        },
      peg$c246 = "par",
      peg$c247 = peg$literalExpectation("par", false),
      peg$c248 = function() {
          return { kind: "parbreak", location: location() };
        },

      peg$currPos          = 0,
      peg$savedPos         = 0,
      peg$posDetailsCache  = [{ line: 1, column: 1 }],
      peg$maxFailPos       = 0,
      peg$maxFailExpected  = [],
      peg$silentFails      = 0,

      peg$tracer = "tracer" in options ? options.tracer : new peg$DefaultTracer(),

      peg$result;

  if ("startRule" in options) {
    if (!(options.startRule in peg$startRuleFunctions)) {
      throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
    }

    peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
  }

  function text() {
    return input.substring(peg$savedPos, peg$currPos);
  }

  function location() {
    return peg$computeLocation(peg$savedPos, peg$currPos);
  }

  function expected(description, location) {
    location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

    throw peg$buildStructuredError(
      [peg$otherExpectation(description)],
      input.substring(peg$savedPos, peg$currPos),
      location
    );
  }

  function error(message, location) {
    location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

    throw peg$buildSimpleError(message, location);
  }

  function peg$literalExpectation(text, ignoreCase) {
    return { type: "literal", text: text, ignoreCase: ignoreCase };
  }

  function peg$classExpectation(parts, inverted, ignoreCase) {
    return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
  }

  function peg$anyExpectation() {
    return { type: "any" };
  }

  function peg$endExpectation() {
    return { type: "end" };
  }

  function peg$otherExpectation(description) {
    return { type: "other", description: description };
  }

  function peg$computePosDetails(pos) {
    var details = peg$posDetailsCache[pos], p;

    if (details) {
      return details;
    } else {
      p = pos - 1;
      while (!peg$posDetailsCache[p]) {
        p--;
      }

      details = peg$posDetailsCache[p];
      details = {
        line:   details.line,
        column: details.column
      };

      while (p < pos) {
        if (input.charCodeAt(p) === 10) {
          details.line++;
          details.column = 1;
        } else {
          details.column++;
        }

        p++;
      }

      peg$posDetailsCache[pos] = details;
      return details;
    }
  }

  function peg$computeLocation(startPos, endPos) {
    var startPosDetails = peg$computePosDetails(startPos),
        endPosDetails   = peg$computePosDetails(endPos);

    return {
      start: {
        offset: startPos,
        line:   startPosDetails.line,
        column: startPosDetails.column
      },
      end: {
        offset: endPos,
        line:   endPosDetails.line,
        column: endPosDetails.column
      }
    };
  }

  function peg$fail(expected) {
    if (peg$currPos < peg$maxFailPos) { return; }

    if (peg$currPos > peg$maxFailPos) {
      peg$maxFailPos = peg$currPos;
      peg$maxFailExpected = [];
    }

    peg$maxFailExpected.push(expected);
  }

  function peg$buildSimpleError(message, location) {
    return new peg$SyntaxError(message, null, null, location);
  }

  function peg$buildStructuredError(expected, found, location) {
    return new peg$SyntaxError(
      peg$SyntaxError.buildMessage(expected, found),
      expected,
      found,
      location
    );
  }

  function peg$parseRoot() {
    var s0, s1, s2, s3,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Root",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseElement();
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parseElement();
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c0(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Root",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Root",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsePreamble() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Preamble",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$currPos;
      peg$silentFails++;
      s5 = peg$currPos;
      s6 = peg$parseskip_space();
      if (s6 !== peg$FAILED) {
        s7 = peg$parseescape();
        if (s7 !== peg$FAILED) {
          if (input.substr(peg$currPos, 15) === peg$c1) {
            s8 = peg$c1;
            peg$currPos += 15;
          } else {
            s8 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c2); }
          }
          if (s8 !== peg$FAILED) {
            s6 = [s6, s7, s8];
            s5 = s6;
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
      } else {
        peg$currPos = s5;
        s5 = peg$FAILED;
      }
      peg$silentFails--;
      if (s5 === peg$FAILED) {
        s4 = void 0;
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parseElement();
        if (s5 !== peg$FAILED) {
          peg$savedPos = s3;
          s4 = peg$c3(s5);
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$currPos;
        peg$silentFails++;
        s5 = peg$currPos;
        s6 = peg$parseskip_space();
        if (s6 !== peg$FAILED) {
          s7 = peg$parseescape();
          if (s7 !== peg$FAILED) {
            if (input.substr(peg$currPos, 15) === peg$c1) {
              s8 = peg$c1;
              peg$currPos += 15;
            } else {
              s8 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c2); }
            }
            if (s8 !== peg$FAILED) {
              s6 = [s6, s7, s8];
              s5 = s6;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
        peg$silentFails--;
        if (s5 === peg$FAILED) {
          s4 = void 0;
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseElement();
          if (s5 !== peg$FAILED) {
            peg$savedPos = s3;
            s4 = peg$c3(s5);
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          s5 = peg$currPos;
          s6 = peg$parseescape();
          if (s6 !== peg$FAILED) {
            if (input.substr(peg$currPos, 15) === peg$c1) {
              s7 = peg$c1;
              peg$currPos += 15;
            } else {
              s7 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c2); }
            }
            if (s7 !== peg$FAILED) {
              s8 = [];
              if (input.length > peg$currPos) {
                s9 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s9 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c4); }
              }
              while (s9 !== peg$FAILED) {
                s8.push(s9);
                if (input.length > peg$currPos) {
                  s9 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s9 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c4); }
                }
              }
              if (s8 !== peg$FAILED) {
                s6 = [s6, s7, s8];
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          if (s5 === peg$FAILED) {
            s5 = null;
          }
          if (s5 !== peg$FAILED) {
            s4 = input.substring(s4, peg$currPos);
          } else {
            s4 = s5;
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c5(s2, s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Preamble",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Preamble",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseElement() {
    var s0, s1, s2, s3,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Element",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseElement_p();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseskip_comment();
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parseskip_comment();
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c6(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Element",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Element",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseElement_p() {
    var s0, s1, s2, s3, s4, s5,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Element_p",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$parseSpecialCommand();
    if (s0 === peg$FAILED) {
      s0 = peg$parsebreak();
      if (s0 === peg$FAILED) {
        s0 = peg$parseLinebreak();
        if (s0 === peg$FAILED) {
          s0 = peg$parseDefCommand();
          if (s0 === peg$FAILED) {
            s0 = peg$parseCommand();
            if (s0 === peg$FAILED) {
              s0 = peg$parseGroup_p();
              if (s0 === peg$FAILED) {
                s0 = peg$parseInlineMathShift();
                if (s0 === peg$FAILED) {
                  s0 = peg$parseAlignmentTab();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parseCommandParameterWithNumber();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parseSuperscript();
                      if (s0 === peg$FAILED) {
                        s0 = peg$parseSubscript();
                        if (s0 === peg$FAILED) {
                          s0 = peg$parseActiveCharacter();
                          if (s0 === peg$FAILED) {
                            s0 = peg$parseignore();
                            if (s0 === peg$FAILED) {
                              s0 = peg$parseSoftbreak();
                              if (s0 === peg$FAILED) {
                                s0 = peg$currPos;
                                s1 = peg$currPos;
                                s2 = [];
                                s3 = peg$currPos;
                                s4 = peg$currPos;
                                peg$silentFails++;
                                s5 = peg$parsenoncharToken();
                                peg$silentFails--;
                                if (s5 === peg$FAILED) {
                                  s4 = void 0;
                                } else {
                                  peg$currPos = s4;
                                  s4 = peg$FAILED;
                                }
                                if (s4 !== peg$FAILED) {
                                  if (input.length > peg$currPos) {
                                    s5 = input.charAt(peg$currPos);
                                    peg$currPos++;
                                  } else {
                                    s5 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c4); }
                                  }
                                  if (s5 !== peg$FAILED) {
                                    s4 = [s4, s5];
                                    s3 = s4;
                                  } else {
                                    peg$currPos = s3;
                                    s3 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s3;
                                  s3 = peg$FAILED;
                                }
                                if (s3 !== peg$FAILED) {
                                  while (s3 !== peg$FAILED) {
                                    s2.push(s3);
                                    s3 = peg$currPos;
                                    s4 = peg$currPos;
                                    peg$silentFails++;
                                    s5 = peg$parsenoncharToken();
                                    peg$silentFails--;
                                    if (s5 === peg$FAILED) {
                                      s4 = void 0;
                                    } else {
                                      peg$currPos = s4;
                                      s4 = peg$FAILED;
                                    }
                                    if (s4 !== peg$FAILED) {
                                      if (input.length > peg$currPos) {
                                        s5 = input.charAt(peg$currPos);
                                        peg$currPos++;
                                      } else {
                                        s5 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c4); }
                                      }
                                      if (s5 !== peg$FAILED) {
                                        s4 = [s4, s5];
                                        s3 = s4;
                                      } else {
                                        peg$currPos = s3;
                                        s3 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s3;
                                      s3 = peg$FAILED;
                                    }
                                  }
                                } else {
                                  s2 = peg$FAILED;
                                }
                                if (s2 !== peg$FAILED) {
                                  s1 = input.substring(s1, peg$currPos);
                                } else {
                                  s1 = s2;
                                }
                                if (s1 !== peg$FAILED) {
                                  peg$savedPos = s0;
                                  s1 = peg$c7(s1);
                                }
                                s0 = s1;
                                if (s0 === peg$FAILED) {
                                  s0 = peg$parseSpace();
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Element_p",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Element_p",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseMathElement() {
    var s0, s1, s2,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "MathElement",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseMathElement_p();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_space();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c6(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "MathElement",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "MathElement",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseMathElement_p() {
    var s0, s1, s2, s3, s4,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "MathElement_p",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$parseMathAlignedEnvironment();
    if (s0 === peg$FAILED) {
      s0 = peg$parseLinebreak();
      if (s0 === peg$FAILED) {
        s0 = peg$parseAmsmathTextCommand();
        if (s0 === peg$FAILED) {
          s0 = peg$parseSpecialCommand();
          if (s0 === peg$FAILED) {
            s0 = peg$parseMatchingDelimiters();
            if (s0 === peg$FAILED) {
              s0 = peg$parseMathematicalDelimiters();
              if (s0 === peg$FAILED) {
                s0 = peg$parseMathCommand();
                if (s0 === peg$FAILED) {
                  s0 = peg$parseMathGroup();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parseAlignmentTab();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parseCommandParameterWithNumber();
                      if (s0 === peg$FAILED) {
                        s0 = peg$parseSuperscriptWithArg();
                        if (s0 === peg$FAILED) {
                          s0 = peg$parseSubscriptWithArg();
                          if (s0 === peg$FAILED) {
                            s0 = peg$parseActiveCharacter();
                            if (s0 === peg$FAILED) {
                              s0 = peg$parseignore();
                              if (s0 === peg$FAILED) {
                                s0 = peg$currPos;
                                s1 = peg$currPos;
                                s2 = peg$currPos;
                                s3 = peg$currPos;
                                peg$silentFails++;
                                s4 = peg$parsenonMathcharToken();
                                peg$silentFails--;
                                if (s4 === peg$FAILED) {
                                  s3 = void 0;
                                } else {
                                  peg$currPos = s3;
                                  s3 = peg$FAILED;
                                }
                                if (s3 !== peg$FAILED) {
                                  if (input.length > peg$currPos) {
                                    s4 = input.charAt(peg$currPos);
                                    peg$currPos++;
                                  } else {
                                    s4 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c4); }
                                  }
                                  if (s4 !== peg$FAILED) {
                                    s3 = [s3, s4];
                                    s2 = s3;
                                  } else {
                                    peg$currPos = s2;
                                    s2 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s2;
                                  s2 = peg$FAILED;
                                }
                                if (s2 !== peg$FAILED) {
                                  s1 = input.substring(s1, peg$currPos);
                                } else {
                                  s1 = s2;
                                }
                                if (s1 !== peg$FAILED) {
                                  peg$savedPos = s0;
                                  s1 = peg$c8(s1);
                                }
                                s0 = s1;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "MathElement_p",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "MathElement_p",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsenonMathcharToken() {
    var s0,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "nonMathcharToken",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$parsemathShift();
    if (s0 === peg$FAILED) {
      s0 = peg$parseescape();
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "nonMathcharToken",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "nonMathcharToken",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsenoncharToken() {
    var s0,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "noncharToken",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$parseescape();
    if (s0 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 37) {
        s0 = peg$c9;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c10); }
      }
      if (s0 === peg$FAILED) {
        s0 = peg$parsebeginGroup();
        if (s0 === peg$FAILED) {
          s0 = peg$parseendGroup();
          if (s0 === peg$FAILED) {
            s0 = peg$parsemathShift();
            if (s0 === peg$FAILED) {
              s0 = peg$parseAlignmentTab();
              if (s0 === peg$FAILED) {
                s0 = peg$parseActiveCharacter();
                if (s0 === peg$FAILED) {
                  s0 = peg$parsenl();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parsecommandParameter();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parseSuperscript();
                      if (s0 === peg$FAILED) {
                        s0 = peg$parseSubscript();
                        if (s0 === peg$FAILED) {
                          s0 = peg$parseignore();
                          if (s0 === peg$FAILED) {
                            s0 = peg$parsesp();
                            if (s0 === peg$FAILED) {
                              s0 = peg$parseEOF();
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "noncharToken",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "noncharToken",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsenumber() {
    var s0, s1, s2, s3, s4, s5,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "number",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = [];
    s3 = peg$parsenum();
    if (s3 !== peg$FAILED) {
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parsenum();
      }
    } else {
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 46) {
        s3 = peg$c11;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c12); }
      }
      if (s3 !== peg$FAILED) {
        s4 = [];
        s5 = peg$parsenum();
        if (s5 !== peg$FAILED) {
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = peg$parsenum();
          }
        } else {
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          s2 = [s2, s3, s4];
          s1 = s2;
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s0 = input.substring(s0, peg$currPos);
    } else {
      s0 = s1;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 46) {
        s2 = peg$c11;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c12); }
      }
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$parsenum();
        if (s4 !== peg$FAILED) {
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$parsenum();
          }
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s2 = [s2, s3];
          s1 = s2;
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        s0 = input.substring(s0, peg$currPos);
      } else {
        s0 = s1;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$currPos;
        s2 = [];
        s3 = peg$parsenum();
        if (s3 !== peg$FAILED) {
          while (s3 !== peg$FAILED) {
            s2.push(s3);
            s3 = peg$parsenum();
          }
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 46) {
            s3 = peg$c11;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c12); }
          }
          if (s3 !== peg$FAILED) {
            s2 = [s2, s3];
            s1 = s2;
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
        if (s1 !== peg$FAILED) {
          s0 = input.substring(s0, peg$currPos);
        } else {
          s0 = s1;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = [];
          s2 = peg$parsenum();
          if (s2 !== peg$FAILED) {
            while (s2 !== peg$FAILED) {
              s1.push(s2);
              s2 = peg$parsenum();
            }
          } else {
            s1 = peg$FAILED;
          }
          if (s1 !== peg$FAILED) {
            s0 = input.substring(s0, peg$currPos);
          } else {
            s0 = s1;
          }
        }
      }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "number",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "number",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseSpecialCommand() {
    var s0, s1,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "SpecialCommand",
      location: peg$computeLocation(startPos, startPos)
    });

    peg$silentFails++;
    s0 = peg$parseUrlCommand();
    if (s0 === peg$FAILED) {
      s0 = peg$parseHrefCommand();
      if (s0 === peg$FAILED) {
        s0 = peg$parseVerb();
        if (s0 === peg$FAILED) {
          s0 = peg$parseVerbatim();
          if (s0 === peg$FAILED) {
            s0 = peg$parseMinted();
            if (s0 === peg$FAILED) {
              s0 = peg$parseLstlisting();
              if (s0 === peg$FAILED) {
                s0 = peg$parsecommentenv();
                if (s0 === peg$FAILED) {
                  s0 = peg$parseDisplayMath();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parseInlineMathParen();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parseMathEnvironment();
                      if (s0 === peg$FAILED) {
                        s0 = peg$parseEnvironment();
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c13); }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "SpecialCommand",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "SpecialCommand",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseLabelCommand() {
    var s0, s1, s2, s3, s4, s5, s6,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "LabelCommand",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 5) === peg$c14) {
        s2 = peg$c14;
        peg$currPos += 5;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c15); }
      }
      if (s2 === peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c16) {
          s2 = peg$c16;
          peg$currPos += 3;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c17); }
        }
        if (s2 === peg$FAILED) {
          if (input.substr(peg$currPos, 5) === peg$c18) {
            s2 = peg$c18;
            peg$currPos += 5;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c19); }
          }
          if (s2 === peg$FAILED) {
            if (input.substr(peg$currPos, 7) === peg$c20) {
              s2 = peg$c20;
              peg$currPos += 7;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c21); }
            }
          }
        }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          s4 = peg$parsebeginGroup();
          if (s4 !== peg$FAILED) {
            s5 = peg$parselabelString();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseendGroup();
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c22(s2, s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "LabelCommand",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "LabelCommand",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parselabelString() {
    var s0, s1, s2, s3, s4,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "labelString",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = [];
    s1 = peg$parseskip_comment();
    if (s1 === peg$FAILED) {
      s1 = peg$currPos;
      s2 = peg$parsepairedCurlySkippingComment();
      if (s2 !== peg$FAILED) {
        s1 = input.substring(s1, peg$currPos);
      } else {
        s1 = s2;
      }
      if (s1 === peg$FAILED) {
        s1 = peg$currPos;
        s2 = peg$currPos;
        s3 = peg$currPos;
        peg$silentFails++;
        if (input.charCodeAt(peg$currPos) === 125) {
          s4 = peg$c23;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c24); }
        }
        peg$silentFails--;
        if (s4 === peg$FAILED) {
          s3 = void 0;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          if (input.length > peg$currPos) {
            s4 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c4); }
          }
          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s1 = input.substring(s1, peg$currPos);
        } else {
          s1 = s2;
        }
      }
    }
    if (s1 !== peg$FAILED) {
      while (s1 !== peg$FAILED) {
        s0.push(s1);
        s1 = peg$parseskip_comment();
        if (s1 === peg$FAILED) {
          s1 = peg$currPos;
          s2 = peg$parsepairedCurlySkippingComment();
          if (s2 !== peg$FAILED) {
            s1 = input.substring(s1, peg$currPos);
          } else {
            s1 = s2;
          }
          if (s1 === peg$FAILED) {
            s1 = peg$currPos;
            s2 = peg$currPos;
            s3 = peg$currPos;
            peg$silentFails++;
            if (input.charCodeAt(peg$currPos) === 125) {
              s4 = peg$c23;
              peg$currPos++;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c24); }
            }
            peg$silentFails--;
            if (s4 === peg$FAILED) {
              s3 = void 0;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
            if (s3 !== peg$FAILED) {
              if (input.length > peg$currPos) {
                s4 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s4 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c4); }
              }
              if (s4 !== peg$FAILED) {
                s3 = [s3, s4];
                s2 = s3;
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
            if (s2 !== peg$FAILED) {
              s1 = input.substring(s1, peg$currPos);
            } else {
              s1 = s2;
            }
          }
        }
      }
    } else {
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "labelString",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "labelString",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsepairedCurlySkippingComment() {
    var s0, s1, s2, s3, s4, s5, s6,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "pairedCurlySkippingComment",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 123) {
      s1 = peg$c25;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c26); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseskip_comment();
      if (s3 === peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parsepairedCurlySkippingComment();
        if (s4 !== peg$FAILED) {
          s3 = input.substring(s3, peg$currPos);
        } else {
          s3 = s4;
        }
        if (s3 === peg$FAILED) {
          s3 = peg$currPos;
          s4 = peg$currPos;
          s5 = peg$currPos;
          peg$silentFails++;
          if (input.charCodeAt(peg$currPos) === 125) {
            s6 = peg$c23;
            peg$currPos++;
          } else {
            s6 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c24); }
          }
          peg$silentFails--;
          if (s6 === peg$FAILED) {
            s5 = void 0;
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            if (input.length > peg$currPos) {
              s6 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c4); }
            }
            if (s6 !== peg$FAILED) {
              s5 = [s5, s6];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            s3 = input.substring(s3, peg$currPos);
          } else {
            s3 = s4;
          }
        }
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parseskip_comment();
        if (s3 === peg$FAILED) {
          s3 = peg$currPos;
          s4 = peg$parsepairedCurlySkippingComment();
          if (s4 !== peg$FAILED) {
            s3 = input.substring(s3, peg$currPos);
          } else {
            s3 = s4;
          }
          if (s3 === peg$FAILED) {
            s3 = peg$currPos;
            s4 = peg$currPos;
            s5 = peg$currPos;
            peg$silentFails++;
            if (input.charCodeAt(peg$currPos) === 125) {
              s6 = peg$c23;
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c24); }
            }
            peg$silentFails--;
            if (s6 === peg$FAILED) {
              s5 = void 0;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 !== peg$FAILED) {
              if (input.length > peg$currPos) {
                s6 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c4); }
              }
              if (s6 !== peg$FAILED) {
                s5 = [s5, s6];
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
            if (s4 !== peg$FAILED) {
              s3 = input.substring(s3, peg$currPos);
            } else {
              s3 = s4;
            }
          }
        }
      }
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 125) {
          s3 = peg$c23;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c24); }
        }
        if (s3 !== peg$FAILED) {
          s1 = [s1, s2, s3];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "pairedCurlySkippingComment",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "pairedCurlySkippingComment",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseUrlCommand() {
    var s0, s1, s2, s3, s4, s5, s6,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "UrlCommand",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 3) === peg$c27) {
        s2 = peg$c27;
        peg$currPos += 3;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c28); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          s4 = peg$parsebeginGroup();
          if (s4 !== peg$FAILED) {
            s5 = peg$currPos;
            s6 = peg$parseurlString();
            if (s6 !== peg$FAILED) {
              s5 = input.substring(s5, peg$currPos);
            } else {
              s5 = s6;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseendGroup();
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c29(s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "UrlCommand",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "UrlCommand",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseHrefCommand() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "HrefCommand",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c30) {
        s2 = peg$c30;
        peg$currPos += 4;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c31); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseArgumentList();
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseskip_space();
          if (s4 !== peg$FAILED) {
            s5 = peg$parsebeginGroup();
            if (s5 !== peg$FAILED) {
              s6 = peg$currPos;
              s7 = peg$parseurlString();
              if (s7 !== peg$FAILED) {
                s6 = input.substring(s6, peg$currPos);
              } else {
                s6 = s7;
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parseendGroup();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseGroup();
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c32(s3, s6, s8);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "HrefCommand",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "HrefCommand",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseurlString() {
    var s0, s1, s2, s3,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "urlString",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = [];
    s1 = peg$parsepairedCurly();
    if (s1 === peg$FAILED) {
      s1 = peg$currPos;
      s2 = peg$currPos;
      peg$silentFails++;
      if (input.charCodeAt(peg$currPos) === 125) {
        s3 = peg$c23;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c24); }
      }
      peg$silentFails--;
      if (s3 === peg$FAILED) {
        s2 = void 0;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        if (input.length > peg$currPos) {
          s3 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c4); }
        }
        if (s3 !== peg$FAILED) {
          s2 = [s2, s3];
          s1 = s2;
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    }
    while (s1 !== peg$FAILED) {
      s0.push(s1);
      s1 = peg$parsepairedCurly();
      if (s1 === peg$FAILED) {
        s1 = peg$currPos;
        s2 = peg$currPos;
        peg$silentFails++;
        if (input.charCodeAt(peg$currPos) === 125) {
          s3 = peg$c23;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c24); }
        }
        peg$silentFails--;
        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          if (input.length > peg$currPos) {
            s3 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c4); }
          }
          if (s3 !== peg$FAILED) {
            s2 = [s2, s3];
            s1 = s2;
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "urlString",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "urlString",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsepairedCurly() {
    var s0, s1, s2, s3, s4, s5,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "pairedCurly",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 123) {
      s1 = peg$c25;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c26); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parsepairedCurly();
      if (s3 === peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$currPos;
        peg$silentFails++;
        if (input.charCodeAt(peg$currPos) === 125) {
          s5 = peg$c23;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c24); }
        }
        peg$silentFails--;
        if (s5 === peg$FAILED) {
          s4 = void 0;
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          if (input.length > peg$currPos) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c4); }
          }
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parsepairedCurly();
        if (s3 === peg$FAILED) {
          s3 = peg$currPos;
          s4 = peg$currPos;
          peg$silentFails++;
          if (input.charCodeAt(peg$currPos) === 125) {
            s5 = peg$c23;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c24); }
          }
          peg$silentFails--;
          if (s5 === peg$FAILED) {
            s4 = void 0;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            if (input.length > peg$currPos) {
              s5 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c4); }
            }
            if (s5 !== peg$FAILED) {
              s4 = [s4, s5];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
      }
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 125) {
          s3 = peg$c23;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c24); }
        }
        if (s3 !== peg$FAILED) {
          s1 = [s1, s2, s3];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "pairedCurly",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "pairedCurly",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseVerb() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Verb",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 5) === peg$c33) {
        s2 = peg$c33;
        peg$currPos += 5;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c34); }
      }
      if (s2 === peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c35) {
          s2 = peg$c35;
          peg$currPos += 4;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c36); }
        }
      }
      if (s2 !== peg$FAILED) {
        if (input.length > peg$currPos) {
          s3 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c4); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          s5 = [];
          s6 = peg$currPos;
          s7 = peg$currPos;
          peg$silentFails++;
          s8 = peg$currPos;
          if (input.length > peg$currPos) {
            s9 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s9 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c4); }
          }
          if (s9 !== peg$FAILED) {
            peg$savedPos = peg$currPos;
            s10 = peg$c37(s2, s3, s9);
            if (s10) {
              s10 = void 0;
            } else {
              s10 = peg$FAILED;
            }
            if (s10 !== peg$FAILED) {
              s9 = [s9, s10];
              s8 = s9;
            } else {
              peg$currPos = s8;
              s8 = peg$FAILED;
            }
          } else {
            peg$currPos = s8;
            s8 = peg$FAILED;
          }
          peg$silentFails--;
          if (s8 === peg$FAILED) {
            s7 = void 0;
          } else {
            peg$currPos = s7;
            s7 = peg$FAILED;
          }
          if (s7 !== peg$FAILED) {
            if (input.length > peg$currPos) {
              s8 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s8 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c4); }
            }
            if (s8 !== peg$FAILED) {
              s7 = [s7, s8];
              s6 = s7;
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
          } else {
            peg$currPos = s6;
            s6 = peg$FAILED;
          }
          while (s6 !== peg$FAILED) {
            s5.push(s6);
            s6 = peg$currPos;
            s7 = peg$currPos;
            peg$silentFails++;
            s8 = peg$currPos;
            if (input.length > peg$currPos) {
              s9 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s9 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c4); }
            }
            if (s9 !== peg$FAILED) {
              peg$savedPos = peg$currPos;
              s10 = peg$c37(s2, s3, s9);
              if (s10) {
                s10 = void 0;
              } else {
                s10 = peg$FAILED;
              }
              if (s10 !== peg$FAILED) {
                s9 = [s9, s10];
                s8 = s9;
              } else {
                peg$currPos = s8;
                s8 = peg$FAILED;
              }
            } else {
              peg$currPos = s8;
              s8 = peg$FAILED;
            }
            peg$silentFails--;
            if (s8 === peg$FAILED) {
              s7 = void 0;
            } else {
              peg$currPos = s7;
              s7 = peg$FAILED;
            }
            if (s7 !== peg$FAILED) {
              if (input.length > peg$currPos) {
                s8 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s8 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c4); }
              }
              if (s8 !== peg$FAILED) {
                s7 = [s7, s8];
                s6 = s7;
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
          }
          if (s5 !== peg$FAILED) {
            s4 = input.substring(s4, peg$currPos);
          } else {
            s4 = s5;
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$currPos;
            if (input.length > peg$currPos) {
              s6 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c4); }
            }
            if (s6 !== peg$FAILED) {
              peg$savedPos = peg$currPos;
              s7 = peg$c38(s2, s3, s4, s6);
              if (s7) {
                s7 = void 0;
              } else {
                s7 = peg$FAILED;
              }
              if (s7 !== peg$FAILED) {
                s6 = [s6, s7];
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c39(s2, s3, s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Verb",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Verb",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseVerbatim() {
    var s0, s1, s2,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Verbatim",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseVerbatim_p();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_space();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c40(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Verbatim",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Verbatim",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseVerbatim_p() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Verbatim_p",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parsebeginEnv();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebeginGroup();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 8) === peg$c41) {
          s3 = peg$c41;
          peg$currPos += 8;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c42); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseendGroup();
          if (s4 !== peg$FAILED) {
            s5 = peg$currPos;
            s6 = [];
            s7 = peg$currPos;
            s8 = peg$currPos;
            peg$silentFails++;
            s9 = peg$currPos;
            s10 = peg$parseendEnv();
            if (s10 !== peg$FAILED) {
              s11 = peg$parsebeginGroup();
              if (s11 !== peg$FAILED) {
                if (input.substr(peg$currPos, 8) === peg$c41) {
                  s12 = peg$c41;
                  peg$currPos += 8;
                } else {
                  s12 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c42); }
                }
                if (s12 !== peg$FAILED) {
                  s13 = peg$parseendGroup();
                  if (s13 !== peg$FAILED) {
                    s10 = [s10, s11, s12, s13];
                    s9 = s10;
                  } else {
                    peg$currPos = s9;
                    s9 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s9;
                  s9 = peg$FAILED;
                }
              } else {
                peg$currPos = s9;
                s9 = peg$FAILED;
              }
            } else {
              peg$currPos = s9;
              s9 = peg$FAILED;
            }
            peg$silentFails--;
            if (s9 === peg$FAILED) {
              s8 = void 0;
            } else {
              peg$currPos = s8;
              s8 = peg$FAILED;
            }
            if (s8 !== peg$FAILED) {
              if (input.length > peg$currPos) {
                s9 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s9 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c4); }
              }
              if (s9 !== peg$FAILED) {
                s8 = [s8, s9];
                s7 = s8;
              } else {
                peg$currPos = s7;
                s7 = peg$FAILED;
              }
            } else {
              peg$currPos = s7;
              s7 = peg$FAILED;
            }
            while (s7 !== peg$FAILED) {
              s6.push(s7);
              s7 = peg$currPos;
              s8 = peg$currPos;
              peg$silentFails++;
              s9 = peg$currPos;
              s10 = peg$parseendEnv();
              if (s10 !== peg$FAILED) {
                s11 = peg$parsebeginGroup();
                if (s11 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 8) === peg$c41) {
                    s12 = peg$c41;
                    peg$currPos += 8;
                  } else {
                    s12 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c42); }
                  }
                  if (s12 !== peg$FAILED) {
                    s13 = peg$parseendGroup();
                    if (s13 !== peg$FAILED) {
                      s10 = [s10, s11, s12, s13];
                      s9 = s10;
                    } else {
                      peg$currPos = s9;
                      s9 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s9;
                    s9 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s9;
                  s9 = peg$FAILED;
                }
              } else {
                peg$currPos = s9;
                s9 = peg$FAILED;
              }
              peg$silentFails--;
              if (s9 === peg$FAILED) {
                s8 = void 0;
              } else {
                peg$currPos = s8;
                s8 = peg$FAILED;
              }
              if (s8 !== peg$FAILED) {
                if (input.length > peg$currPos) {
                  s9 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s9 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c4); }
                }
                if (s9 !== peg$FAILED) {
                  s8 = [s8, s9];
                  s7 = s8;
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
              } else {
                peg$currPos = s7;
                s7 = peg$FAILED;
              }
            }
            if (s6 !== peg$FAILED) {
              s5 = input.substring(s5, peg$currPos);
            } else {
              s5 = s6;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseendEnv();
              if (s6 !== peg$FAILED) {
                s7 = peg$parsebeginGroup();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 8) === peg$c41) {
                    s8 = peg$c41;
                    peg$currPos += 8;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c42); }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseendGroup();
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c43(s5);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parsebeginEnv();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebeginGroup();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 9) === peg$c44) {
            s3 = peg$c44;
            peg$currPos += 9;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c45); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parseendGroup();
            if (s4 !== peg$FAILED) {
              s5 = peg$currPos;
              s6 = [];
              s7 = peg$currPos;
              s8 = peg$currPos;
              peg$silentFails++;
              s9 = peg$currPos;
              s10 = peg$parseendEnv();
              if (s10 !== peg$FAILED) {
                s11 = peg$parsebeginGroup();
                if (s11 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 9) === peg$c44) {
                    s12 = peg$c44;
                    peg$currPos += 9;
                  } else {
                    s12 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c45); }
                  }
                  if (s12 !== peg$FAILED) {
                    s13 = peg$parseendGroup();
                    if (s13 !== peg$FAILED) {
                      s10 = [s10, s11, s12, s13];
                      s9 = s10;
                    } else {
                      peg$currPos = s9;
                      s9 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s9;
                    s9 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s9;
                  s9 = peg$FAILED;
                }
              } else {
                peg$currPos = s9;
                s9 = peg$FAILED;
              }
              peg$silentFails--;
              if (s9 === peg$FAILED) {
                s8 = void 0;
              } else {
                peg$currPos = s8;
                s8 = peg$FAILED;
              }
              if (s8 !== peg$FAILED) {
                if (input.length > peg$currPos) {
                  s9 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s9 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c4); }
                }
                if (s9 !== peg$FAILED) {
                  s8 = [s8, s9];
                  s7 = s8;
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
              } else {
                peg$currPos = s7;
                s7 = peg$FAILED;
              }
              while (s7 !== peg$FAILED) {
                s6.push(s7);
                s7 = peg$currPos;
                s8 = peg$currPos;
                peg$silentFails++;
                s9 = peg$currPos;
                s10 = peg$parseendEnv();
                if (s10 !== peg$FAILED) {
                  s11 = peg$parsebeginGroup();
                  if (s11 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 9) === peg$c44) {
                      s12 = peg$c44;
                      peg$currPos += 9;
                    } else {
                      s12 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c45); }
                    }
                    if (s12 !== peg$FAILED) {
                      s13 = peg$parseendGroup();
                      if (s13 !== peg$FAILED) {
                        s10 = [s10, s11, s12, s13];
                        s9 = s10;
                      } else {
                        peg$currPos = s9;
                        s9 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s9;
                      s9 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s9;
                    s9 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s9;
                  s9 = peg$FAILED;
                }
                peg$silentFails--;
                if (s9 === peg$FAILED) {
                  s8 = void 0;
                } else {
                  peg$currPos = s8;
                  s8 = peg$FAILED;
                }
                if (s8 !== peg$FAILED) {
                  if (input.length > peg$currPos) {
                    s9 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s9 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c4); }
                  }
                  if (s9 !== peg$FAILED) {
                    s8 = [s8, s9];
                    s7 = s8;
                  } else {
                    peg$currPos = s7;
                    s7 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
              }
              if (s6 !== peg$FAILED) {
                s5 = input.substring(s5, peg$currPos);
              } else {
                s5 = s6;
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parseendEnv();
                if (s6 !== peg$FAILED) {
                  s7 = peg$parsebeginGroup();
                  if (s7 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 9) === peg$c44) {
                      s8 = peg$c44;
                      peg$currPos += 9;
                    } else {
                      s8 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c45); }
                    }
                    if (s8 !== peg$FAILED) {
                      s9 = peg$parseendGroup();
                      if (s9 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c46(s5);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Verbatim_p",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Verbatim_p",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseMinted() {
    var s0, s1, s2,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Minted",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseMinted_p();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_space();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c40(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Minted",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Minted",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseMinted_p() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Minted_p",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parsebeginEnv();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebeginGroup();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 6) === peg$c47) {
          s3 = peg$c47;
          peg$currPos += 6;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c48); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseendGroup();
          if (s4 !== peg$FAILED) {
            s5 = peg$currPos;
            s6 = peg$parseArgumentList();
            if (s6 !== peg$FAILED) {
              s7 = peg$parseGroup();
              if (s7 !== peg$FAILED) {
                s6 = [s6, s7];
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 === peg$FAILED) {
              s5 = peg$parseGroup();
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$currPos;
              s7 = [];
              s8 = peg$currPos;
              s9 = peg$currPos;
              peg$silentFails++;
              s10 = peg$currPos;
              s11 = peg$parseendEnv();
              if (s11 !== peg$FAILED) {
                s12 = peg$parsebeginGroup();
                if (s12 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 6) === peg$c47) {
                    s13 = peg$c47;
                    peg$currPos += 6;
                  } else {
                    s13 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c48); }
                  }
                  if (s13 !== peg$FAILED) {
                    s14 = peg$parseendGroup();
                    if (s14 !== peg$FAILED) {
                      s11 = [s11, s12, s13, s14];
                      s10 = s11;
                    } else {
                      peg$currPos = s10;
                      s10 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s10;
                    s10 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s10;
                  s10 = peg$FAILED;
                }
              } else {
                peg$currPos = s10;
                s10 = peg$FAILED;
              }
              peg$silentFails--;
              if (s10 === peg$FAILED) {
                s9 = void 0;
              } else {
                peg$currPos = s9;
                s9 = peg$FAILED;
              }
              if (s9 !== peg$FAILED) {
                if (input.length > peg$currPos) {
                  s10 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s10 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c4); }
                }
                if (s10 !== peg$FAILED) {
                  s9 = [s9, s10];
                  s8 = s9;
                } else {
                  peg$currPos = s8;
                  s8 = peg$FAILED;
                }
              } else {
                peg$currPos = s8;
                s8 = peg$FAILED;
              }
              while (s8 !== peg$FAILED) {
                s7.push(s8);
                s8 = peg$currPos;
                s9 = peg$currPos;
                peg$silentFails++;
                s10 = peg$currPos;
                s11 = peg$parseendEnv();
                if (s11 !== peg$FAILED) {
                  s12 = peg$parsebeginGroup();
                  if (s12 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 6) === peg$c47) {
                      s13 = peg$c47;
                      peg$currPos += 6;
                    } else {
                      s13 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c48); }
                    }
                    if (s13 !== peg$FAILED) {
                      s14 = peg$parseendGroup();
                      if (s14 !== peg$FAILED) {
                        s11 = [s11, s12, s13, s14];
                        s10 = s11;
                      } else {
                        peg$currPos = s10;
                        s10 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s10;
                      s10 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s10;
                    s10 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s10;
                  s10 = peg$FAILED;
                }
                peg$silentFails--;
                if (s10 === peg$FAILED) {
                  s9 = void 0;
                } else {
                  peg$currPos = s9;
                  s9 = peg$FAILED;
                }
                if (s9 !== peg$FAILED) {
                  if (input.length > peg$currPos) {
                    s10 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s10 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c4); }
                  }
                  if (s10 !== peg$FAILED) {
                    s9 = [s9, s10];
                    s8 = s9;
                  } else {
                    peg$currPos = s8;
                    s8 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s8;
                  s8 = peg$FAILED;
                }
              }
              if (s7 !== peg$FAILED) {
                s6 = input.substring(s6, peg$currPos);
              } else {
                s6 = s7;
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parseendEnv();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parsebeginGroup();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 6) === peg$c47) {
                      s9 = peg$c47;
                      peg$currPos += 6;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c48); }
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parseendGroup();
                      if (s10 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c49(s5, s6);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Minted_p",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Minted_p",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseLstlisting() {
    var s0, s1, s2,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Lstlisting",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseLstlisting_p();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_space();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c40(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Lstlisting",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Lstlisting",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseLstlisting_p() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Lstlisting_p",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parsebeginEnv();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebeginGroup();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 10) === peg$c50) {
          s3 = peg$c50;
          peg$currPos += 10;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c51); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseendGroup();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseArgumentList();
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$currPos;
              s7 = [];
              s8 = peg$currPos;
              s9 = peg$currPos;
              peg$silentFails++;
              s10 = peg$currPos;
              s11 = peg$parseendEnv();
              if (s11 !== peg$FAILED) {
                s12 = peg$parsebeginGroup();
                if (s12 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 10) === peg$c50) {
                    s13 = peg$c50;
                    peg$currPos += 10;
                  } else {
                    s13 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c51); }
                  }
                  if (s13 !== peg$FAILED) {
                    s14 = peg$parseendGroup();
                    if (s14 !== peg$FAILED) {
                      s11 = [s11, s12, s13, s14];
                      s10 = s11;
                    } else {
                      peg$currPos = s10;
                      s10 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s10;
                    s10 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s10;
                  s10 = peg$FAILED;
                }
              } else {
                peg$currPos = s10;
                s10 = peg$FAILED;
              }
              peg$silentFails--;
              if (s10 === peg$FAILED) {
                s9 = void 0;
              } else {
                peg$currPos = s9;
                s9 = peg$FAILED;
              }
              if (s9 !== peg$FAILED) {
                if (input.length > peg$currPos) {
                  s10 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s10 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c4); }
                }
                if (s10 !== peg$FAILED) {
                  s9 = [s9, s10];
                  s8 = s9;
                } else {
                  peg$currPos = s8;
                  s8 = peg$FAILED;
                }
              } else {
                peg$currPos = s8;
                s8 = peg$FAILED;
              }
              while (s8 !== peg$FAILED) {
                s7.push(s8);
                s8 = peg$currPos;
                s9 = peg$currPos;
                peg$silentFails++;
                s10 = peg$currPos;
                s11 = peg$parseendEnv();
                if (s11 !== peg$FAILED) {
                  s12 = peg$parsebeginGroup();
                  if (s12 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 10) === peg$c50) {
                      s13 = peg$c50;
                      peg$currPos += 10;
                    } else {
                      s13 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c51); }
                    }
                    if (s13 !== peg$FAILED) {
                      s14 = peg$parseendGroup();
                      if (s14 !== peg$FAILED) {
                        s11 = [s11, s12, s13, s14];
                        s10 = s11;
                      } else {
                        peg$currPos = s10;
                        s10 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s10;
                      s10 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s10;
                    s10 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s10;
                  s10 = peg$FAILED;
                }
                peg$silentFails--;
                if (s10 === peg$FAILED) {
                  s9 = void 0;
                } else {
                  peg$currPos = s9;
                  s9 = peg$FAILED;
                }
                if (s9 !== peg$FAILED) {
                  if (input.length > peg$currPos) {
                    s10 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s10 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c4); }
                  }
                  if (s10 !== peg$FAILED) {
                    s9 = [s9, s10];
                    s8 = s9;
                  } else {
                    peg$currPos = s8;
                    s8 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s8;
                  s8 = peg$FAILED;
                }
              }
              if (s7 !== peg$FAILED) {
                s6 = input.substring(s6, peg$currPos);
              } else {
                s6 = s7;
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parseendEnv();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parsebeginGroup();
                  if (s8 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 10) === peg$c50) {
                      s9 = peg$c50;
                      peg$currPos += 10;
                    } else {
                      s9 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c51); }
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parseendGroup();
                      if (s10 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c52(s5, s6);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Lstlisting_p",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Lstlisting_p",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsecommentenv() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "commentenv",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parsebeginEnv();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebeginGroup();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 7) === peg$c53) {
          s3 = peg$c53;
          peg$currPos += 7;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c54); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseendGroup();
          if (s4 !== peg$FAILED) {
            s5 = peg$currPos;
            s6 = [];
            s7 = peg$currPos;
            s8 = peg$currPos;
            peg$silentFails++;
            s9 = peg$currPos;
            s10 = peg$parseendEnv();
            if (s10 !== peg$FAILED) {
              s11 = peg$parsebeginGroup();
              if (s11 !== peg$FAILED) {
                if (input.substr(peg$currPos, 7) === peg$c53) {
                  s12 = peg$c53;
                  peg$currPos += 7;
                } else {
                  s12 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c54); }
                }
                if (s12 !== peg$FAILED) {
                  s13 = peg$parseendGroup();
                  if (s13 !== peg$FAILED) {
                    s10 = [s10, s11, s12, s13];
                    s9 = s10;
                  } else {
                    peg$currPos = s9;
                    s9 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s9;
                  s9 = peg$FAILED;
                }
              } else {
                peg$currPos = s9;
                s9 = peg$FAILED;
              }
            } else {
              peg$currPos = s9;
              s9 = peg$FAILED;
            }
            peg$silentFails--;
            if (s9 === peg$FAILED) {
              s8 = void 0;
            } else {
              peg$currPos = s8;
              s8 = peg$FAILED;
            }
            if (s8 !== peg$FAILED) {
              if (input.length > peg$currPos) {
                s9 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s9 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c4); }
              }
              if (s9 !== peg$FAILED) {
                s8 = [s8, s9];
                s7 = s8;
              } else {
                peg$currPos = s7;
                s7 = peg$FAILED;
              }
            } else {
              peg$currPos = s7;
              s7 = peg$FAILED;
            }
            while (s7 !== peg$FAILED) {
              s6.push(s7);
              s7 = peg$currPos;
              s8 = peg$currPos;
              peg$silentFails++;
              s9 = peg$currPos;
              s10 = peg$parseendEnv();
              if (s10 !== peg$FAILED) {
                s11 = peg$parsebeginGroup();
                if (s11 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 7) === peg$c53) {
                    s12 = peg$c53;
                    peg$currPos += 7;
                  } else {
                    s12 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c54); }
                  }
                  if (s12 !== peg$FAILED) {
                    s13 = peg$parseendGroup();
                    if (s13 !== peg$FAILED) {
                      s10 = [s10, s11, s12, s13];
                      s9 = s10;
                    } else {
                      peg$currPos = s9;
                      s9 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s9;
                    s9 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s9;
                  s9 = peg$FAILED;
                }
              } else {
                peg$currPos = s9;
                s9 = peg$FAILED;
              }
              peg$silentFails--;
              if (s9 === peg$FAILED) {
                s8 = void 0;
              } else {
                peg$currPos = s8;
                s8 = peg$FAILED;
              }
              if (s8 !== peg$FAILED) {
                if (input.length > peg$currPos) {
                  s9 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s9 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c4); }
                }
                if (s9 !== peg$FAILED) {
                  s8 = [s8, s9];
                  s7 = s8;
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
              } else {
                peg$currPos = s7;
                s7 = peg$FAILED;
              }
            }
            if (s6 !== peg$FAILED) {
              s5 = input.substring(s5, peg$currPos);
            } else {
              s5 = s6;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseendEnv();
              if (s6 !== peg$FAILED) {
                s7 = peg$parsebeginGroup();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 7) === peg$c53) {
                    s8 = peg$c53;
                    peg$currPos += 7;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c54); }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseendGroup();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parseskip_space();
                      if (s10 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c55(s5);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "commentenv",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "commentenv",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseInlineMathShift() {
    var s0, s1, s2, s3, s4, s5, s6,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "InlineMathShift",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parsemathShift();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_space();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$currPos;
        s5 = peg$currPos;
        peg$silentFails++;
        s6 = peg$parsemathShift();
        peg$silentFails--;
        if (s6 === peg$FAILED) {
          s5 = void 0;
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parseMathElement();
          if (s6 !== peg$FAILED) {
            peg$savedPos = s4;
            s5 = peg$c56(s6);
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$currPos;
            s5 = peg$currPos;
            peg$silentFails++;
            s6 = peg$parsemathShift();
            peg$silentFails--;
            if (s6 === peg$FAILED) {
              s5 = void 0;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseMathElement();
              if (s6 !== peg$FAILED) {
                peg$savedPos = s4;
                s5 = peg$c56(s6);
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          }
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parsemathShift();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c57(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parsemathShift();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsewhitespace();
        if (s2 !== peg$FAILED) {
          s3 = [];
          s4 = peg$currPos;
          s5 = peg$currPos;
          peg$silentFails++;
          s6 = peg$parsemathShift();
          peg$silentFails--;
          if (s6 === peg$FAILED) {
            s5 = void 0;
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parseMathElement();
            if (s6 !== peg$FAILED) {
              peg$savedPos = s4;
              s5 = peg$c56(s6);
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$currPos;
            s5 = peg$currPos;
            peg$silentFails++;
            s6 = peg$parsemathShift();
            peg$silentFails--;
            if (s6 === peg$FAILED) {
              s5 = void 0;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseMathElement();
              if (s6 !== peg$FAILED) {
                peg$savedPos = s4;
                s5 = peg$c56(s6);
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parsemathShift();
            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c57(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "InlineMathShift",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "InlineMathShift",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseInlineMathParen() {
    var s0, s1, s2, s3, s4, s5, s6,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "InlineMathParen",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parsebeginInlineMath();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_space();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$currPos;
        s5 = peg$currPos;
        peg$silentFails++;
        s6 = peg$parseendInlineMath();
        peg$silentFails--;
        if (s6 === peg$FAILED) {
          s5 = void 0;
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parseMathElement();
          if (s6 !== peg$FAILED) {
            peg$savedPos = s4;
            s5 = peg$c58(s6);
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$currPos;
          s5 = peg$currPos;
          peg$silentFails++;
          s6 = peg$parseendInlineMath();
          peg$silentFails--;
          if (s6 === peg$FAILED) {
            s5 = void 0;
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parseMathElement();
            if (s6 !== peg$FAILED) {
              peg$savedPos = s4;
              s5 = peg$c58(s6);
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseendInlineMath();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c59(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "InlineMathParen",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "InlineMathParen",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseDisplayMath() {
    var s0,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "DisplayMath",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$parsedisplayMathSquareBracket();
    if (s0 === peg$FAILED) {
      s0 = peg$parsedisplayMathShiftShift();
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "DisplayMath",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "DisplayMath",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsedisplayMathSquareBracket() {
    var s0, s1, s2, s3, s4, s5, s6,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "displayMathSquareBracket",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parsebeginDisplayMath();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_space();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$currPos;
        s5 = peg$currPos;
        peg$silentFails++;
        s6 = peg$parseendDisplayMath();
        peg$silentFails--;
        if (s6 === peg$FAILED) {
          s5 = void 0;
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parseMathElement();
          if (s6 !== peg$FAILED) {
            peg$savedPos = s4;
            s5 = peg$c58(s6);
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$currPos;
          s5 = peg$currPos;
          peg$silentFails++;
          s6 = peg$parseendDisplayMath();
          peg$silentFails--;
          if (s6 === peg$FAILED) {
            s5 = void 0;
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parseMathElement();
            if (s6 !== peg$FAILED) {
              peg$savedPos = s4;
              s5 = peg$c58(s6);
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseendDisplayMath();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c60(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "displayMathSquareBracket",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "displayMathSquareBracket",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsedisplayMathShiftShift() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "displayMathShiftShift",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parsemathShift();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsemathShift();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$currPos;
          s6 = peg$currPos;
          peg$silentFails++;
          s7 = peg$currPos;
          s8 = peg$parsemathShift();
          if (s8 !== peg$FAILED) {
            s9 = peg$parsemathShift();
            if (s9 !== peg$FAILED) {
              s8 = [s8, s9];
              s7 = s8;
            } else {
              peg$currPos = s7;
              s7 = peg$FAILED;
            }
          } else {
            peg$currPos = s7;
            s7 = peg$FAILED;
          }
          peg$silentFails--;
          if (s7 === peg$FAILED) {
            s6 = void 0;
          } else {
            peg$currPos = s6;
            s6 = peg$FAILED;
          }
          if (s6 !== peg$FAILED) {
            s7 = peg$parseMathElement();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s5;
              s6 = peg$c58(s7);
              s5 = s6;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = peg$currPos;
            s6 = peg$currPos;
            peg$silentFails++;
            s7 = peg$currPos;
            s8 = peg$parsemathShift();
            if (s8 !== peg$FAILED) {
              s9 = peg$parsemathShift();
              if (s9 !== peg$FAILED) {
                s8 = [s8, s9];
                s7 = s8;
              } else {
                peg$currPos = s7;
                s7 = peg$FAILED;
              }
            } else {
              peg$currPos = s7;
              s7 = peg$FAILED;
            }
            peg$silentFails--;
            if (s7 === peg$FAILED) {
              s6 = void 0;
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            if (s6 !== peg$FAILED) {
              s7 = peg$parseMathElement();
              if (s7 !== peg$FAILED) {
                peg$savedPos = s5;
                s6 = peg$c58(s7);
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parsemathShift();
            if (s5 !== peg$FAILED) {
              s6 = peg$parsemathShift();
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c60(s4);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "displayMathShiftShift",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "displayMathShiftShift",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseCommand() {
    var s0, s1, s2, s3, s4,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Command",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$parsePrimitiveCharacterCommand();
    if (s0 === peg$FAILED) {
      s0 = peg$parseLabelCommand();
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseescape();
        if (s1 !== peg$FAILED) {
          s2 = peg$parsecommandName();
          if (s2 !== peg$FAILED) {
            s3 = [];
            s4 = peg$parseArgumentList();
            if (s4 === peg$FAILED) {
              s4 = peg$parseGroup();
            }
            if (s4 !== peg$FAILED) {
              while (s4 !== peg$FAILED) {
                s3.push(s4);
                s4 = peg$parseArgumentList();
                if (s4 === peg$FAILED) {
                  s4 = peg$parseGroup();
                }
              }
            } else {
              s3 = peg$FAILED;
            }
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c61(s2, s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseCommand_p();
          if (s1 !== peg$FAILED) {
            s2 = peg$parseskip_space();
            if (s2 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c40(s1);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        }
      }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Command",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Command",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseCommand_p() {
    var s0, s1, s2,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Command_p",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsecommandName();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c62(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Command_p",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Command_p",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsecommandName() {
    var s0, s1, s2, s3,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "commandName",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = [];
    s3 = peg$parsechar();
    if (s3 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 64) {
        s3 = peg$c63;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c64); }
      }
    }
    if (s3 !== peg$FAILED) {
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parsechar();
        if (s3 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 64) {
            s3 = peg$c63;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c64); }
          }
        }
      }
    } else {
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      s1 = input.substring(s1, peg$currPos);
    } else {
      s1 = s2;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_space();
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 42) {
          s3 = peg$c65;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c66); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c67(s1);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parsechar();
      if (s2 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 64) {
          s2 = peg$c63;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c64); }
        }
      }
      if (s2 !== peg$FAILED) {
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          s2 = peg$parsechar();
          if (s2 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 64) {
              s2 = peg$c63;
              peg$currPos++;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c64); }
            }
          }
        }
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        s0 = input.substring(s0, peg$currPos);
      } else {
        s0 = s1;
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c68) {
          s0 = peg$c68;
          peg$currPos += 2;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c69); }
        }
        if (s0 === peg$FAILED) {
          if (input.length > peg$currPos) {
            s0 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c4); }
          }
        }
      }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "commandName",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "commandName",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsePrimitiveCharacterCommand() {
    var s0, s1, s2,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "PrimitiveCharacterCommand",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      if (peg$c70.test(input.charAt(peg$currPos))) {
        s2 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c71); }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c72(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "PrimitiveCharacterCommand",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "PrimitiveCharacterCommand",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseMathCommand() {
    var s0, s1, s2, s3, s4, s5,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "MathCommand",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$parseLabelCommand();
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseescape();
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$currPos;
        s4 = peg$currPos;
        peg$silentFails++;
        s5 = peg$parsenonMathCommandName();
        peg$silentFails--;
        if (s5 === peg$FAILED) {
          s4 = void 0;
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parsecommandName();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s2 = input.substring(s2, peg$currPos);
        } else {
          s2 = s3;
        }
        if (s2 !== peg$FAILED) {
          s3 = [];
          s4 = peg$parseArgumentList();
          if (s4 === peg$FAILED) {
            s4 = peg$parseMathGroup();
          }
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$parseArgumentList();
            if (s4 === peg$FAILED) {
              s4 = peg$parseMathGroup();
            }
          }
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c61(s2, s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "MathCommand",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "MathCommand",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsenonMathCommandName() {
    var s0, s1, s2,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "nonMathCommandName",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c73) {
      s1 = peg$c73;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c74); }
    }
    if (s1 !== peg$FAILED) {
      if (peg$c75.test(input.charAt(peg$currPos))) {
        s2 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c76); }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 91) {
        s0 = peg$c77;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c78); }
      }
      if (s0 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 93) {
          s0 = peg$c79;
          peg$currPos++;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c80); }
        }
        if (s0 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 40) {
            s0 = peg$c81;
            peg$currPos++;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c82); }
          }
          if (s0 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 41) {
              s0 = peg$c83;
              peg$currPos++;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c84); }
            }
          }
        }
      }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "nonMathCommandName",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "nonMathCommandName",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseDefCommand() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "DefCommand",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 3) === peg$c85) {
        s2 = peg$c85;
        peg$currPos += 3;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c86); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          s5 = peg$currPos;
          s6 = peg$parseescape();
          if (s6 !== peg$FAILED) {
            s7 = [];
            s8 = peg$parsechar();
            if (s8 === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 64) {
                s8 = peg$c63;
                peg$currPos++;
              } else {
                s8 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c64); }
              }
            }
            if (s8 !== peg$FAILED) {
              while (s8 !== peg$FAILED) {
                s7.push(s8);
                s8 = peg$parsechar();
                if (s8 === peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 64) {
                    s8 = peg$c63;
                    peg$currPos++;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c64); }
                  }
                }
              }
            } else {
              s7 = peg$FAILED;
            }
            if (s7 !== peg$FAILED) {
              s6 = [s6, s7];
              s5 = s6;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            s4 = input.substring(s4, peg$currPos);
          } else {
            s4 = s5;
          }
          if (s4 !== peg$FAILED) {
            s5 = [];
            s6 = peg$parseArgumentList();
            if (s6 === peg$FAILED) {
              s6 = peg$parseCommandParameterWithNumber();
            }
            while (s6 !== peg$FAILED) {
              s5.push(s6);
              s6 = peg$parseArgumentList();
              if (s6 === peg$FAILED) {
                s6 = peg$parseCommandParameterWithNumber();
              }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseGroup();
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c87(s4, s5, s6);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "DefCommand",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "DefCommand",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseGroup() {
    var s0, s1, s2,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Group",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseGroup_p();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c40(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Group",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Group",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseGroup_p() {
    var s0, s1, s2, s3, s4, s5, s6,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Group_p",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parsebeginGroup();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseskip_comment();
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parseskip_comment();
      }
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$currPos;
        s5 = peg$currPos;
        peg$silentFails++;
        s6 = peg$parseendGroup();
        peg$silentFails--;
        if (s6 === peg$FAILED) {
          s5 = void 0;
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parseElement();
          if (s6 !== peg$FAILED) {
            peg$savedPos = s4;
            s5 = peg$c88(s6);
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$currPos;
          s5 = peg$currPos;
          peg$silentFails++;
          s6 = peg$parseendGroup();
          peg$silentFails--;
          if (s6 === peg$FAILED) {
            s5 = void 0;
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parseElement();
            if (s6 !== peg$FAILED) {
              peg$savedPos = s4;
              s5 = peg$c88(s6);
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseendGroup();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c89(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Group_p",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Group_p",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseMathGroup() {
    var s0, s1, s2,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "MathGroup",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseMathGroup_p();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c40(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "MathGroup",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "MathGroup",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseMathGroup_p() {
    var s0, s1, s2, s3, s4, s5, s6,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "MathGroup_p",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parsebeginGroup();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_space();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$currPos;
        s5 = peg$currPos;
        peg$silentFails++;
        s6 = peg$parseendGroup();
        peg$silentFails--;
        if (s6 === peg$FAILED) {
          s5 = void 0;
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parseMathElement();
          if (s6 !== peg$FAILED) {
            peg$savedPos = s4;
            s5 = peg$c88(s6);
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$currPos;
          s5 = peg$currPos;
          peg$silentFails++;
          s6 = peg$parseendGroup();
          peg$silentFails--;
          if (s6 === peg$FAILED) {
            s5 = void 0;
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parseMathElement();
            if (s6 !== peg$FAILED) {
              peg$savedPos = s4;
              s5 = peg$c88(s6);
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseendGroup();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c89(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "MathGroup_p",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "MathGroup_p",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseMatchingDelimiters() {
    var s0, s1, s2,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "MatchingDelimiters",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseMatchingDelimiters_p();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c40(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "MatchingDelimiters",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "MatchingDelimiters",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseMatchingDelimiters_p() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "MatchingDelimiters_p",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c90) {
        s2 = peg$c90;
        peg$currPos += 4;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c91); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          s5 = peg$parsemathDelimiter();
          if (s5 !== peg$FAILED) {
            s4 = input.substring(s4, peg$currPos);
          } else {
            s4 = s5;
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseskip_space();
            if (s5 !== peg$FAILED) {
              s6 = [];
              s7 = peg$currPos;
              s8 = peg$currPos;
              peg$silentFails++;
              s9 = peg$currPos;
              s10 = peg$parseescape();
              if (s10 !== peg$FAILED) {
                if (input.substr(peg$currPos, 5) === peg$c92) {
                  s11 = peg$c92;
                  peg$currPos += 5;
                } else {
                  s11 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c93); }
                }
                if (s11 !== peg$FAILED) {
                  s12 = peg$parsemathDelimiter();
                  if (s12 !== peg$FAILED) {
                    s10 = [s10, s11, s12];
                    s9 = s10;
                  } else {
                    peg$currPos = s9;
                    s9 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s9;
                  s9 = peg$FAILED;
                }
              } else {
                peg$currPos = s9;
                s9 = peg$FAILED;
              }
              peg$silentFails--;
              if (s9 === peg$FAILED) {
                s8 = void 0;
              } else {
                peg$currPos = s8;
                s8 = peg$FAILED;
              }
              if (s8 !== peg$FAILED) {
                s9 = peg$parseMathElement();
                if (s9 !== peg$FAILED) {
                  peg$savedPos = s7;
                  s8 = peg$c94(s4, s9);
                  s7 = s8;
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
              } else {
                peg$currPos = s7;
                s7 = peg$FAILED;
              }
              while (s7 !== peg$FAILED) {
                s6.push(s7);
                s7 = peg$currPos;
                s8 = peg$currPos;
                peg$silentFails++;
                s9 = peg$currPos;
                s10 = peg$parseescape();
                if (s10 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 5) === peg$c92) {
                    s11 = peg$c92;
                    peg$currPos += 5;
                  } else {
                    s11 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c93); }
                  }
                  if (s11 !== peg$FAILED) {
                    s12 = peg$parsemathDelimiter();
                    if (s12 !== peg$FAILED) {
                      s10 = [s10, s11, s12];
                      s9 = s10;
                    } else {
                      peg$currPos = s9;
                      s9 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s9;
                    s9 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s9;
                  s9 = peg$FAILED;
                }
                peg$silentFails--;
                if (s9 === peg$FAILED) {
                  s8 = void 0;
                } else {
                  peg$currPos = s8;
                  s8 = peg$FAILED;
                }
                if (s8 !== peg$FAILED) {
                  s9 = peg$parseMathElement();
                  if (s9 !== peg$FAILED) {
                    peg$savedPos = s7;
                    s8 = peg$c94(s4, s9);
                    s7 = s8;
                  } else {
                    peg$currPos = s7;
                    s7 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parseescape();
                if (s7 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 5) === peg$c92) {
                    s8 = peg$c92;
                    peg$currPos += 5;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c93); }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseskip_space();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$currPos;
                      s11 = peg$parsemathDelimiter();
                      if (s11 !== peg$FAILED) {
                        s10 = input.substring(s10, peg$currPos);
                      } else {
                        s10 = s11;
                      }
                      if (s10 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c95(s4, s6, s10);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "MatchingDelimiters_p",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "MatchingDelimiters_p",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsemathDelimiter() {
    var s0, s1, s2, s3,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "mathDelimiter",
      location: peg$computeLocation(startPos, startPos)
    });

    if (peg$c96.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c97); }
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseescape();
      if (s1 !== peg$FAILED) {
        if (peg$c98.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c99); }
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseescape();
        if (s1 !== peg$FAILED) {
          s2 = [];
          s3 = peg$parsechar();
          if (s3 !== peg$FAILED) {
            while (s3 !== peg$FAILED) {
              s2.push(s3);
              s3 = peg$parsechar();
            }
          } else {
            s2 = peg$FAILED;
          }
          if (s2 !== peg$FAILED) {
            s1 = [s1, s2];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "mathDelimiter",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "mathDelimiter",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseMathematicalDelimiters() {
    var s0, s1, s2,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "MathematicalDelimiters",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseMathematicalDelimiters_p();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c40(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "MathematicalDelimiters",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "MathematicalDelimiters",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseMathematicalDelimiters_p() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "MathematicalDelimiters_p",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$parsesizeCommand();
    if (s2 === peg$FAILED) {
      s2 = null;
    }
    if (s2 !== peg$FAILED) {
      s1 = input.substring(s1, peg$currPos);
    } else {
      s1 = s2;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_space();
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 40) {
          s3 = peg$c81;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c82); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseskip_space();
          if (s4 !== peg$FAILED) {
            s5 = [];
            s6 = peg$currPos;
            s7 = peg$currPos;
            peg$silentFails++;
            s8 = peg$currPos;
            s9 = peg$parsesizeCommand();
            if (s9 === peg$FAILED) {
              s9 = null;
            }
            if (s9 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 41) {
                s10 = peg$c83;
                peg$currPos++;
              } else {
                s10 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c84); }
              }
              if (s10 !== peg$FAILED) {
                s9 = [s9, s10];
                s8 = s9;
              } else {
                peg$currPos = s8;
                s8 = peg$FAILED;
              }
            } else {
              peg$currPos = s8;
              s8 = peg$FAILED;
            }
            peg$silentFails--;
            if (s8 === peg$FAILED) {
              s7 = void 0;
            } else {
              peg$currPos = s7;
              s7 = peg$FAILED;
            }
            if (s7 !== peg$FAILED) {
              s8 = peg$parseMathElement();
              if (s8 !== peg$FAILED) {
                peg$savedPos = s6;
                s7 = peg$c94(s1, s8);
                s6 = s7;
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            while (s6 !== peg$FAILED) {
              s5.push(s6);
              s6 = peg$currPos;
              s7 = peg$currPos;
              peg$silentFails++;
              s8 = peg$currPos;
              s9 = peg$parsesizeCommand();
              if (s9 === peg$FAILED) {
                s9 = null;
              }
              if (s9 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 41) {
                  s10 = peg$c83;
                  peg$currPos++;
                } else {
                  s10 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c84); }
                }
                if (s10 !== peg$FAILED) {
                  s9 = [s9, s10];
                  s8 = s9;
                } else {
                  peg$currPos = s8;
                  s8 = peg$FAILED;
                }
              } else {
                peg$currPos = s8;
                s8 = peg$FAILED;
              }
              peg$silentFails--;
              if (s8 === peg$FAILED) {
                s7 = void 0;
              } else {
                peg$currPos = s7;
                s7 = peg$FAILED;
              }
              if (s7 !== peg$FAILED) {
                s8 = peg$parseMathElement();
                if (s8 !== peg$FAILED) {
                  peg$savedPos = s6;
                  s7 = peg$c94(s1, s8);
                  s6 = s7;
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$currPos;
              s7 = peg$parsesizeCommand();
              if (s7 === peg$FAILED) {
                s7 = null;
              }
              if (s7 !== peg$FAILED) {
                s6 = input.substring(s6, peg$currPos);
              } else {
                s6 = s7;
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parseskip_space();
                if (s7 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 41) {
                    s8 = peg$c83;
                    peg$currPos++;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c84); }
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c100(s1, s5, s6);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$currPos;
      s2 = peg$parsesizeCommand();
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      if (s2 !== peg$FAILED) {
        s1 = input.substring(s1, peg$currPos);
      } else {
        s1 = s2;
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parseskip_space();
        if (s2 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 91) {
            s3 = peg$c77;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c78); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parseskip_space();
            if (s4 !== peg$FAILED) {
              s5 = [];
              s6 = peg$currPos;
              s7 = peg$currPos;
              peg$silentFails++;
              s8 = peg$currPos;
              s9 = peg$parsesizeCommand();
              if (s9 === peg$FAILED) {
                s9 = null;
              }
              if (s9 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 93) {
                  s10 = peg$c79;
                  peg$currPos++;
                } else {
                  s10 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c80); }
                }
                if (s10 !== peg$FAILED) {
                  s9 = [s9, s10];
                  s8 = s9;
                } else {
                  peg$currPos = s8;
                  s8 = peg$FAILED;
                }
              } else {
                peg$currPos = s8;
                s8 = peg$FAILED;
              }
              peg$silentFails--;
              if (s8 === peg$FAILED) {
                s7 = void 0;
              } else {
                peg$currPos = s7;
                s7 = peg$FAILED;
              }
              if (s7 !== peg$FAILED) {
                s8 = peg$parseMathElement();
                if (s8 !== peg$FAILED) {
                  peg$savedPos = s6;
                  s7 = peg$c94(s1, s8);
                  s6 = s7;
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
              while (s6 !== peg$FAILED) {
                s5.push(s6);
                s6 = peg$currPos;
                s7 = peg$currPos;
                peg$silentFails++;
                s8 = peg$currPos;
                s9 = peg$parsesizeCommand();
                if (s9 === peg$FAILED) {
                  s9 = null;
                }
                if (s9 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 93) {
                    s10 = peg$c79;
                    peg$currPos++;
                  } else {
                    s10 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c80); }
                  }
                  if (s10 !== peg$FAILED) {
                    s9 = [s9, s10];
                    s8 = s9;
                  } else {
                    peg$currPos = s8;
                    s8 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s8;
                  s8 = peg$FAILED;
                }
                peg$silentFails--;
                if (s8 === peg$FAILED) {
                  s7 = void 0;
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseMathElement();
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s6;
                    s7 = peg$c94(s1, s8);
                    s6 = s7;
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$currPos;
                s7 = peg$parsesizeCommand();
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  s6 = input.substring(s6, peg$currPos);
                } else {
                  s6 = s7;
                }
                if (s6 !== peg$FAILED) {
                  s7 = peg$parseskip_space();
                  if (s7 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 93) {
                      s8 = peg$c79;
                      peg$currPos++;
                    } else {
                      s8 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c80); }
                    }
                    if (s8 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c101(s1, s5, s6);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$currPos;
        s2 = peg$parsesizeCommand();
        if (s2 === peg$FAILED) {
          s2 = null;
        }
        if (s2 !== peg$FAILED) {
          s1 = input.substring(s1, peg$currPos);
        } else {
          s1 = s2;
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parseskip_space();
          if (s2 !== peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c102) {
              s3 = peg$c102;
              peg$currPos += 2;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c103); }
            }
            if (s3 !== peg$FAILED) {
              s4 = peg$parseskip_space();
              if (s4 !== peg$FAILED) {
                s5 = [];
                s6 = peg$currPos;
                s7 = peg$currPos;
                peg$silentFails++;
                s8 = peg$currPos;
                s9 = peg$parsesizeCommand();
                if (s9 === peg$FAILED) {
                  s9 = null;
                }
                if (s9 !== peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c104) {
                    s10 = peg$c104;
                    peg$currPos += 2;
                  } else {
                    s10 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c105); }
                  }
                  if (s10 !== peg$FAILED) {
                    s9 = [s9, s10];
                    s8 = s9;
                  } else {
                    peg$currPos = s8;
                    s8 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s8;
                  s8 = peg$FAILED;
                }
                peg$silentFails--;
                if (s8 === peg$FAILED) {
                  s7 = void 0;
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseMathElement();
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s6;
                    s7 = peg$c94(s1, s8);
                    s6 = s7;
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
                while (s6 !== peg$FAILED) {
                  s5.push(s6);
                  s6 = peg$currPos;
                  s7 = peg$currPos;
                  peg$silentFails++;
                  s8 = peg$currPos;
                  s9 = peg$parsesizeCommand();
                  if (s9 === peg$FAILED) {
                    s9 = null;
                  }
                  if (s9 !== peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c104) {
                      s10 = peg$c104;
                      peg$currPos += 2;
                    } else {
                      s10 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c105); }
                    }
                    if (s10 !== peg$FAILED) {
                      s9 = [s9, s10];
                      s8 = s9;
                    } else {
                      peg$currPos = s8;
                      s8 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s8;
                    s8 = peg$FAILED;
                  }
                  peg$silentFails--;
                  if (s8 === peg$FAILED) {
                    s7 = void 0;
                  } else {
                    peg$currPos = s7;
                    s7 = peg$FAILED;
                  }
                  if (s7 !== peg$FAILED) {
                    s8 = peg$parseMathElement();
                    if (s8 !== peg$FAILED) {
                      peg$savedPos = s6;
                      s7 = peg$c94(s1, s8);
                      s6 = s7;
                    } else {
                      peg$currPos = s6;
                      s6 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                }
                if (s5 !== peg$FAILED) {
                  s6 = peg$currPos;
                  s7 = peg$parsesizeCommand();
                  if (s7 === peg$FAILED) {
                    s7 = null;
                  }
                  if (s7 !== peg$FAILED) {
                    s6 = input.substring(s6, peg$currPos);
                  } else {
                    s6 = s7;
                  }
                  if (s6 !== peg$FAILED) {
                    s7 = peg$parseskip_space();
                    if (s7 !== peg$FAILED) {
                      if (input.substr(peg$currPos, 2) === peg$c104) {
                        s8 = peg$c104;
                        peg$currPos += 2;
                      } else {
                        s8 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c105); }
                      }
                      if (s8 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c106(s1, s5, s6);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "MathematicalDelimiters_p",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "MathematicalDelimiters_p",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsesizeCommand() {
    var s0, s1, s2, s3,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "sizeCommand",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c107) {
        s2 = peg$c107;
        peg$currPos += 4;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c108); }
      }
      if (s2 === peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c109) {
          s2 = peg$c109;
          peg$currPos += 4;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c110); }
        }
        if (s2 === peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c111) {
            s2 = peg$c111;
            peg$currPos += 3;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c112); }
          }
          if (s2 === peg$FAILED) {
            if (input.substr(peg$currPos, 3) === peg$c113) {
              s2 = peg$c113;
              peg$currPos += 3;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c114); }
            }
          }
        }
      }
      if (s2 !== peg$FAILED) {
        if (peg$c115.test(input.charAt(peg$currPos))) {
          s3 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c116); }
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s1 = [s1, s2, s3];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "sizeCommand",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "sizeCommand",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseArgumentList() {
    var s0, s1, s2,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "ArgumentList",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseArgumentList_p();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c40(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "ArgumentList",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "ArgumentList",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseArgumentList_p() {
    var s0, s1, s2, s3, s4, s5, s6, s7,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "ArgumentList_p",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 91) {
      s1 = peg$c77;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c78); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_space();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$currPos;
        s5 = peg$currPos;
        peg$silentFails++;
        if (input.charCodeAt(peg$currPos) === 93) {
          s6 = peg$c79;
          peg$currPos++;
        } else {
          s6 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c80); }
        }
        peg$silentFails--;
        if (s6 === peg$FAILED) {
          s5 = void 0;
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parseargsDelimiter();
          if (s6 === peg$FAILED) {
            s6 = peg$parseargsToken();
          }
          if (s6 !== peg$FAILED) {
            s7 = peg$parseskip_space();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s4;
              s5 = peg$c58(s6);
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$currPos;
          s5 = peg$currPos;
          peg$silentFails++;
          if (input.charCodeAt(peg$currPos) === 93) {
            s6 = peg$c79;
            peg$currPos++;
          } else {
            s6 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c80); }
          }
          peg$silentFails--;
          if (s6 === peg$FAILED) {
            s5 = void 0;
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parseargsDelimiter();
            if (s6 === peg$FAILED) {
              s6 = peg$parseargsToken();
            }
            if (s6 !== peg$FAILED) {
              s7 = peg$parseskip_space();
              if (s7 !== peg$FAILED) {
                peg$savedPos = s4;
                s5 = peg$c58(s6);
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        }
        if (s3 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 93) {
            s4 = peg$c79;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c80); }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c117(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "ArgumentList_p",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "ArgumentList_p",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseargsToken() {
    var s0, s1, s2, s3, s4, s5, s6,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "argsToken",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$parseSpecialCommand();
    if (s0 === peg$FAILED) {
      s0 = peg$parseCommand();
      if (s0 === peg$FAILED) {
        s0 = peg$parseGroup();
        if (s0 === peg$FAILED) {
          s0 = peg$parseInlineMathShift();
          if (s0 === peg$FAILED) {
            s0 = peg$parseAlignmentTab();
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              s1 = [];
              s2 = peg$parsesp();
              while (s2 !== peg$FAILED) {
                s1.push(s2);
                s2 = peg$parsesp();
              }
              if (s1 !== peg$FAILED) {
                s2 = peg$parsenl();
                if (s2 !== peg$FAILED) {
                  s3 = [];
                  s4 = peg$parsesp();
                  while (s4 !== peg$FAILED) {
                    s3.push(s4);
                    s4 = peg$parsesp();
                  }
                  if (s3 !== peg$FAILED) {
                    s4 = [];
                    s5 = peg$parsenl();
                    if (s5 !== peg$FAILED) {
                      while (s5 !== peg$FAILED) {
                        s4.push(s5);
                        s5 = peg$parsenl();
                      }
                    } else {
                      s4 = peg$FAILED;
                    }
                    if (s4 !== peg$FAILED) {
                      s5 = [];
                      s6 = peg$parsesp();
                      while (s6 !== peg$FAILED) {
                        s5.push(s6);
                        s6 = peg$parsesp();
                      }
                      if (s5 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c118();
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 === peg$FAILED) {
                s0 = peg$parseCommandParameterWithNumber();
                if (s0 === peg$FAILED) {
                  s0 = peg$parseSuperscript();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parseSubscript();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parseignore();
                      if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        s1 = peg$currPos;
                        s2 = [];
                        s3 = peg$currPos;
                        s4 = peg$currPos;
                        peg$silentFails++;
                        s5 = peg$parsenoncharToken();
                        if (s5 === peg$FAILED) {
                          if (input.charCodeAt(peg$currPos) === 44) {
                            s5 = peg$c119;
                            peg$currPos++;
                          } else {
                            s5 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c120); }
                          }
                          if (s5 === peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 93) {
                              s5 = peg$c79;
                              peg$currPos++;
                            } else {
                              s5 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c80); }
                            }
                          }
                        }
                        peg$silentFails--;
                        if (s5 === peg$FAILED) {
                          s4 = void 0;
                        } else {
                          peg$currPos = s4;
                          s4 = peg$FAILED;
                        }
                        if (s4 !== peg$FAILED) {
                          if (input.length > peg$currPos) {
                            s5 = input.charAt(peg$currPos);
                            peg$currPos++;
                          } else {
                            s5 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c4); }
                          }
                          if (s5 !== peg$FAILED) {
                            s4 = [s4, s5];
                            s3 = s4;
                          } else {
                            peg$currPos = s3;
                            s3 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s3;
                          s3 = peg$FAILED;
                        }
                        if (s3 !== peg$FAILED) {
                          while (s3 !== peg$FAILED) {
                            s2.push(s3);
                            s3 = peg$currPos;
                            s4 = peg$currPos;
                            peg$silentFails++;
                            s5 = peg$parsenoncharToken();
                            if (s5 === peg$FAILED) {
                              if (input.charCodeAt(peg$currPos) === 44) {
                                s5 = peg$c119;
                                peg$currPos++;
                              } else {
                                s5 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c120); }
                              }
                              if (s5 === peg$FAILED) {
                                if (input.charCodeAt(peg$currPos) === 93) {
                                  s5 = peg$c79;
                                  peg$currPos++;
                                } else {
                                  s5 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c80); }
                                }
                              }
                            }
                            peg$silentFails--;
                            if (s5 === peg$FAILED) {
                              s4 = void 0;
                            } else {
                              peg$currPos = s4;
                              s4 = peg$FAILED;
                            }
                            if (s4 !== peg$FAILED) {
                              if (input.length > peg$currPos) {
                                s5 = input.charAt(peg$currPos);
                                peg$currPos++;
                              } else {
                                s5 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c4); }
                              }
                              if (s5 !== peg$FAILED) {
                                s4 = [s4, s5];
                                s3 = s4;
                              } else {
                                peg$currPos = s3;
                                s3 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s3;
                              s3 = peg$FAILED;
                            }
                          }
                        } else {
                          s2 = peg$FAILED;
                        }
                        if (s2 !== peg$FAILED) {
                          s1 = input.substring(s1, peg$currPos);
                        } else {
                          s1 = s2;
                        }
                        if (s1 !== peg$FAILED) {
                          peg$savedPos = s0;
                          s1 = peg$c121(s1);
                        }
                        s0 = s1;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "argsToken",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "argsToken",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseargsDelimiter() {
    var s0, s1,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "argsDelimiter",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 44) {
      s1 = peg$c119;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c120); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c122();
    }
    s0 = s1;

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "argsDelimiter",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "argsDelimiter",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseEnvironment() {
    var s0, s1, s2, s3,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Environment",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseEnvironment_p();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c40(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Environment",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Environment",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseEnvironment_p() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Environment_p",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parsebeginEnv();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsegroupedEnvname();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$parseArgumentList();
        if (s4 === peg$FAILED) {
          s4 = peg$parseGroup();
        }
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$parseArgumentList();
          if (s4 === peg$FAILED) {
            s4 = peg$parseGroup();
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseskip_space();
          if (s4 !== peg$FAILED) {
            s5 = [];
            s6 = peg$currPos;
            s7 = peg$currPos;
            peg$silentFails++;
            s8 = peg$currPos;
            s9 = peg$parseskip_space();
            if (s9 !== peg$FAILED) {
              s10 = peg$parseendEnv();
              if (s10 !== peg$FAILED) {
                s9 = [s9, s10];
                s8 = s9;
              } else {
                peg$currPos = s8;
                s8 = peg$FAILED;
              }
            } else {
              peg$currPos = s8;
              s8 = peg$FAILED;
            }
            peg$silentFails--;
            if (s8 === peg$FAILED) {
              s7 = void 0;
            } else {
              peg$currPos = s7;
              s7 = peg$FAILED;
            }
            if (s7 !== peg$FAILED) {
              s8 = peg$parseElement();
              if (s8 !== peg$FAILED) {
                peg$savedPos = s6;
                s7 = peg$c123(s2, s3, s8);
                s6 = s7;
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            while (s6 !== peg$FAILED) {
              s5.push(s6);
              s6 = peg$currPos;
              s7 = peg$currPos;
              peg$silentFails++;
              s8 = peg$currPos;
              s9 = peg$parseskip_space();
              if (s9 !== peg$FAILED) {
                s10 = peg$parseendEnv();
                if (s10 !== peg$FAILED) {
                  s9 = [s9, s10];
                  s8 = s9;
                } else {
                  peg$currPos = s8;
                  s8 = peg$FAILED;
                }
              } else {
                peg$currPos = s8;
                s8 = peg$FAILED;
              }
              peg$silentFails--;
              if (s8 === peg$FAILED) {
                s7 = void 0;
              } else {
                peg$currPos = s7;
                s7 = peg$FAILED;
              }
              if (s7 !== peg$FAILED) {
                s8 = peg$parseElement();
                if (s8 !== peg$FAILED) {
                  peg$savedPos = s6;
                  s7 = peg$c123(s2, s3, s8);
                  s6 = s7;
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseskip_space();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseendEnv();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parsegroupedEnvname();
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = peg$currPos;
                    s9 = peg$c124(s2, s3, s5, s8);
                    if (s9) {
                      s9 = void 0;
                    } else {
                      s9 = peg$FAILED;
                    }
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c125(s2, s3, s5, s8);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Environment_p",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Environment_p",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseMathEnvironment() {
    var s0, s1, s2, s3,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "MathEnvironment",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseMathEnvironment_p();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c40(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "MathEnvironment",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "MathEnvironment",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseMathEnvironment_p() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "MathEnvironment_p",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parsebeginEnv();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_space();
      if (s2 !== peg$FAILED) {
        s3 = peg$parsebeginGroup();
        if (s3 !== peg$FAILED) {
          s4 = peg$parsemathEnvName();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseendGroup();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseskip_space();
              if (s6 !== peg$FAILED) {
                s7 = [];
                s8 = peg$currPos;
                s9 = peg$currPos;
                peg$silentFails++;
                s10 = peg$currPos;
                s11 = peg$parseskip_space();
                if (s11 !== peg$FAILED) {
                  s12 = peg$parseendEnv();
                  if (s12 !== peg$FAILED) {
                    s11 = [s11, s12];
                    s10 = s11;
                  } else {
                    peg$currPos = s10;
                    s10 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s10;
                  s10 = peg$FAILED;
                }
                peg$silentFails--;
                if (s10 === peg$FAILED) {
                  s9 = void 0;
                } else {
                  peg$currPos = s9;
                  s9 = peg$FAILED;
                }
                if (s9 !== peg$FAILED) {
                  s10 = peg$parseMathElement();
                  if (s10 !== peg$FAILED) {
                    peg$savedPos = s8;
                    s9 = peg$c126(s4, s10);
                    s8 = s9;
                  } else {
                    peg$currPos = s8;
                    s8 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s8;
                  s8 = peg$FAILED;
                }
                while (s8 !== peg$FAILED) {
                  s7.push(s8);
                  s8 = peg$currPos;
                  s9 = peg$currPos;
                  peg$silentFails++;
                  s10 = peg$currPos;
                  s11 = peg$parseskip_space();
                  if (s11 !== peg$FAILED) {
                    s12 = peg$parseendEnv();
                    if (s12 !== peg$FAILED) {
                      s11 = [s11, s12];
                      s10 = s11;
                    } else {
                      peg$currPos = s10;
                      s10 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s10;
                    s10 = peg$FAILED;
                  }
                  peg$silentFails--;
                  if (s10 === peg$FAILED) {
                    s9 = void 0;
                  } else {
                    peg$currPos = s9;
                    s9 = peg$FAILED;
                  }
                  if (s9 !== peg$FAILED) {
                    s10 = peg$parseMathElement();
                    if (s10 !== peg$FAILED) {
                      peg$savedPos = s8;
                      s9 = peg$c126(s4, s10);
                      s8 = s9;
                    } else {
                      peg$currPos = s8;
                      s8 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s8;
                    s8 = peg$FAILED;
                  }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseskip_space();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseendEnv();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parseskip_space();
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parsebeginGroup();
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parsemathEnvName();
                          if (s12 !== peg$FAILED) {
                            s13 = peg$parseendGroup();
                            if (s13 !== peg$FAILED) {
                              peg$savedPos = peg$currPos;
                              s14 = peg$c127(s4, s7, s12);
                              if (s14) {
                                s14 = void 0;
                              } else {
                                s14 = peg$FAILED;
                              }
                              if (s14 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c128(s4, s7, s12);
                                s0 = s1;
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "MathEnvironment_p",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "MathEnvironment_p",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseMathAlignedEnvironment() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "MathAlignedEnvironment",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parsebeginEnv();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_space();
      if (s2 !== peg$FAILED) {
        s3 = peg$parsebeginGroup();
        if (s3 !== peg$FAILED) {
          s4 = peg$parsemahtAlignedEnvName();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseendGroup();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseskip_space();
              if (s6 !== peg$FAILED) {
                s7 = [];
                s8 = peg$currPos;
                s9 = peg$currPos;
                peg$silentFails++;
                s10 = peg$parseendEnv();
                peg$silentFails--;
                if (s10 === peg$FAILED) {
                  s9 = void 0;
                } else {
                  peg$currPos = s9;
                  s9 = peg$FAILED;
                }
                if (s9 !== peg$FAILED) {
                  s10 = peg$parseMathElement();
                  if (s10 !== peg$FAILED) {
                    peg$savedPos = s8;
                    s9 = peg$c126(s4, s10);
                    s8 = s9;
                  } else {
                    peg$currPos = s8;
                    s8 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s8;
                  s8 = peg$FAILED;
                }
                while (s8 !== peg$FAILED) {
                  s7.push(s8);
                  s8 = peg$currPos;
                  s9 = peg$currPos;
                  peg$silentFails++;
                  s10 = peg$parseendEnv();
                  peg$silentFails--;
                  if (s10 === peg$FAILED) {
                    s9 = void 0;
                  } else {
                    peg$currPos = s9;
                    s9 = peg$FAILED;
                  }
                  if (s9 !== peg$FAILED) {
                    s10 = peg$parseMathElement();
                    if (s10 !== peg$FAILED) {
                      peg$savedPos = s8;
                      s9 = peg$c126(s4, s10);
                      s8 = s9;
                    } else {
                      peg$currPos = s8;
                      s8 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s8;
                    s8 = peg$FAILED;
                  }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseendEnv();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseskip_space();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parsebeginGroup();
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parsemahtAlignedEnvName();
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parseendGroup();
                          if (s12 !== peg$FAILED) {
                            peg$savedPos = peg$currPos;
                            s13 = peg$c127(s4, s7, s11);
                            if (s13) {
                              s13 = void 0;
                            } else {
                              s13 = peg$FAILED;
                            }
                            if (s13 !== peg$FAILED) {
                              peg$savedPos = s0;
                              s1 = peg$c129(s4, s7, s11);
                              s0 = s1;
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "MathAlignedEnvironment",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "MathAlignedEnvironment",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsegroupedEnvname() {
    var s0, s1, s2, s3, s4, s5, s6,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "groupedEnvname",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebeginGroup();
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$currPos;
        s5 = [];
        s6 = peg$parsechar();
        if (s6 !== peg$FAILED) {
          while (s6 !== peg$FAILED) {
            s5.push(s6);
            s6 = peg$parsechar();
          }
        } else {
          s5 = peg$FAILED;
        }
        if (s5 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 42) {
            s6 = peg$c65;
            peg$currPos++;
          } else {
            s6 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c66); }
          }
          if (s6 === peg$FAILED) {
            s6 = null;
          }
          if (s6 !== peg$FAILED) {
            s5 = [s5, s6];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          s3 = input.substring(s3, peg$currPos);
        } else {
          s3 = s4;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseendGroup();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c40(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "groupedEnvname",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "groupedEnvname",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseAmsmathTextCommand() {
    var s0, s1, s2, s3,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "AmsmathTextCommand",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c130) {
        s2 = peg$c130;
        peg$currPos += 4;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c131); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseGroup();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c132(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "AmsmathTextCommand",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "AmsmathTextCommand",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsefull_comment() {
    var s0, s1, s2,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "full_comment",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parsenl();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsecomment();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c133(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parsecomment();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c133(s1);
      }
      s0 = s1;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "full_comment",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "full_comment",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsebeginDisplayMath() {
    var s0, s1, s2,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "beginDisplayMath",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 91) {
        s2 = peg$c77;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c78); }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "beginDisplayMath",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "beginDisplayMath",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseendDisplayMath() {
    var s0, s1, s2,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "endDisplayMath",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 93) {
        s2 = peg$c79;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c80); }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "endDisplayMath",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "endDisplayMath",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsebeginInlineMath() {
    var s0, s1, s2,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "beginInlineMath",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 40) {
        s2 = peg$c81;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c82); }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "beginInlineMath",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "beginInlineMath",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseendInlineMath() {
    var s0, s1, s2,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "endInlineMath",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 41) {
        s2 = peg$c83;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c84); }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "endInlineMath",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "endInlineMath",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsebeginEnv() {
    var s0, s1, s2,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "beginEnv",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 5) === peg$c134) {
        s2 = peg$c134;
        peg$currPos += 5;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c135); }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "beginEnv",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "beginEnv",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseendEnv() {
    var s0, s1, s2,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "endEnv",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 3) === peg$c73) {
        s2 = peg$c73;
        peg$currPos += 3;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c74); }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "endEnv",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "endEnv",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsemathEnvName() {
    var s0,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "mathEnvName",
      location: peg$computeLocation(startPos, startPos)
    });

    if (input.substr(peg$currPos, 9) === peg$c136) {
      s0 = peg$c136;
      peg$currPos += 9;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c137); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 8) === peg$c138) {
        s0 = peg$c138;
        peg$currPos += 8;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c139); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 6) === peg$c140) {
          s0 = peg$c140;
          peg$currPos += 6;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c141); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 5) === peg$c142) {
            s0 = peg$c142;
            peg$currPos += 5;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c143); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 8) === peg$c144) {
              s0 = peg$c144;
              peg$currPos += 8;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c145); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 7) === peg$c146) {
                s0 = peg$c146;
                peg$currPos += 7;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c147); }
              }
              if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 7) === peg$c148) {
                  s0 = peg$c148;
                  peg$currPos += 7;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c149); }
                }
                if (s0 === peg$FAILED) {
                  if (input.substr(peg$currPos, 6) === peg$c150) {
                    s0 = peg$c150;
                    peg$currPos += 6;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c151); }
                  }
                  if (s0 === peg$FAILED) {
                    if (input.substr(peg$currPos, 9) === peg$c152) {
                      s0 = peg$c152;
                      peg$currPos += 9;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c153); }
                    }
                    if (s0 === peg$FAILED) {
                      if (input.substr(peg$currPos, 8) === peg$c154) {
                        s0 = peg$c154;
                        peg$currPos += 8;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c155); }
                      }
                      if (s0 === peg$FAILED) {
                        if (input.substr(peg$currPos, 8) === peg$c156) {
                          s0 = peg$c156;
                          peg$currPos += 8;
                        } else {
                          s0 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c157); }
                        }
                        if (s0 === peg$FAILED) {
                          if (input.substr(peg$currPos, 7) === peg$c158) {
                            s0 = peg$c158;
                            peg$currPos += 7;
                          } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c159); }
                          }
                          if (s0 === peg$FAILED) {
                            if (input.substr(peg$currPos, 4) === peg$c160) {
                              s0 = peg$c160;
                              peg$currPos += 4;
                            } else {
                              s0 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c161); }
                            }
                            if (s0 === peg$FAILED) {
                              if (input.substr(peg$currPos, 11) === peg$c162) {
                                s0 = peg$c162;
                                peg$currPos += 11;
                              } else {
                                s0 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c163); }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "mathEnvName",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "mathEnvName",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsemahtAlignedEnvName() {
    var s0,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "mahtAlignedEnvName",
      location: peg$computeLocation(startPos, startPos)
    });

    if (input.substr(peg$currPos, 9) === peg$c164) {
      s0 = peg$c164;
      peg$currPos += 9;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c165); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 7) === peg$c166) {
        s0 = peg$c166;
        peg$currPos += 7;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c167); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 6) === peg$c168) {
          s0 = peg$c168;
          peg$currPos += 6;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c169); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 5) === peg$c170) {
            s0 = peg$c170;
            peg$currPos += 5;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c171); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 8) === peg$c172) {
              s0 = peg$c172;
              peg$currPos += 8;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c173); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 5) === peg$c174) {
                s0 = peg$c174;
                peg$currPos += 5;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c175); }
              }
              if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 6) === peg$c176) {
                  s0 = peg$c176;
                  peg$currPos += 6;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c177); }
                }
                if (s0 === peg$FAILED) {
                  if (input.substr(peg$currPos, 7) === peg$c178) {
                    s0 = peg$c178;
                    peg$currPos += 7;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c179); }
                  }
                  if (s0 === peg$FAILED) {
                    if (input.substr(peg$currPos, 7) === peg$c180) {
                      s0 = peg$c180;
                      peg$currPos += 7;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c181); }
                    }
                    if (s0 === peg$FAILED) {
                      if (input.substr(peg$currPos, 7) === peg$c182) {
                        s0 = peg$c182;
                        peg$currPos += 7;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c183); }
                      }
                      if (s0 === peg$FAILED) {
                        if (input.substr(peg$currPos, 7) === peg$c184) {
                          s0 = peg$c184;
                          peg$currPos += 7;
                        } else {
                          s0 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c185); }
                        }
                        if (s0 === peg$FAILED) {
                          if (input.substr(peg$currPos, 7) === peg$c186) {
                            s0 = peg$c186;
                            peg$currPos += 7;
                          } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c187); }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "mahtAlignedEnvName",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "mahtAlignedEnvName",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseescape() {
    var s0,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "escape",
      location: peg$computeLocation(startPos, startPos)
    });

    if (input.charCodeAt(peg$currPos) === 92) {
      s0 = peg$c188;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c189); }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "escape",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "escape",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsebeginGroup() {
    var s0,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "beginGroup",
      location: peg$computeLocation(startPos, startPos)
    });

    if (input.charCodeAt(peg$currPos) === 123) {
      s0 = peg$c25;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c26); }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "beginGroup",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "beginGroup",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseendGroup() {
    var s0,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "endGroup",
      location: peg$computeLocation(startPos, startPos)
    });

    if (input.charCodeAt(peg$currPos) === 125) {
      s0 = peg$c23;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c24); }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "endGroup",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "endGroup",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsemathShift() {
    var s0,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "mathShift",
      location: peg$computeLocation(startPos, startPos)
    });

    if (input.charCodeAt(peg$currPos) === 36) {
      s0 = peg$c190;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c191); }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "mathShift",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "mathShift",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseAlignmentTab() {
    var s0, s1,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "AlignmentTab",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 38) {
      s1 = peg$c192;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c193); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c194();
    }
    s0 = s1;

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "AlignmentTab",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "AlignmentTab",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsecommandParameter() {
    var s0,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "commandParameter",
      location: peg$computeLocation(startPos, startPos)
    });

    if (input.charCodeAt(peg$currPos) === 35) {
      s0 = peg$c195;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c196); }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "commandParameter",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "commandParameter",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseSuperscriptWithArg() {
    var s0, s1, s2, s3,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "SuperscriptWithArg",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseSuperscript();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_space();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseMathElement();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c197(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "SuperscriptWithArg",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "SuperscriptWithArg",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseSuperscript() {
    var s0, s1,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Superscript",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 94) {
      s1 = peg$c198;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c199); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c200();
    }
    s0 = s1;

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Superscript",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Superscript",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseSubscriptWithArg() {
    var s0, s1, s2, s3,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "SubscriptWithArg",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseSubscript();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_space();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseMathElement();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c201(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "SubscriptWithArg",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "SubscriptWithArg",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseSubscript() {
    var s0, s1,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Subscript",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 95) {
      s1 = peg$c202;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c203); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c204();
    }
    s0 = s1;

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Subscript",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Subscript",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseignore() {
    var s0, s1,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "ignore",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 0) {
      s1 = peg$c205;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c206); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c207();
    }
    s0 = s1;

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "ignore",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "ignore",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsechar() {
    var s0,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "char",
      location: peg$computeLocation(startPos, startPos)
    });

    if (peg$c208.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c209); }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "char",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "char",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsenum() {
    var s0,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "num",
      location: peg$computeLocation(startPos, startPos)
    });

    if (peg$c210.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c211); }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "num",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "num",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsepunctuation() {
    var s0,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "punctuation",
      location: peg$computeLocation(startPos, startPos)
    });

    if (peg$c212.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c213); }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "punctuation",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "punctuation",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseActiveCharacter() {
    var s0, s1,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "ActiveCharacter",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 126) {
      s1 = peg$c214;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c215); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c216();
    }
    s0 = s1;

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "ActiveCharacter",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "ActiveCharacter",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseCommandParameterWithNumber() {
    var s0, s1, s2, s3, s4,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "CommandParameterWithNumber",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parsecommandParameter();
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      s3 = [];
      s4 = peg$parsenum();
      while (s4 !== peg$FAILED) {
        s3.push(s4);
        s4 = peg$parsenum();
      }
      if (s3 !== peg$FAILED) {
        s2 = input.substring(s2, peg$currPos);
      } else {
        s2 = s3;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c217(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "CommandParameterWithNumber",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "CommandParameterWithNumber",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseendDoc() {
    var s0, s1, s2, s3, s4, s5,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "endDoc",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseendEnv();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_space();
      if (s2 !== peg$FAILED) {
        s3 = peg$parsebeginGroup();
        if (s3 !== peg$FAILED) {
          if (input.substr(peg$currPos, 8) === peg$c218) {
            s4 = peg$c218;
            peg$currPos += 8;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c219); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseendGroup();
            if (s5 !== peg$FAILED) {
              s1 = [s1, s2, s3, s4, s5];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "endDoc",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "endDoc",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsecomment() {
    var s0, s1, s2, s3, s4, s5, s6,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "comment",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 37) {
      s1 = peg$c9;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c10); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      s3 = [];
      s4 = peg$currPos;
      s5 = peg$currPos;
      peg$silentFails++;
      s6 = peg$parsenl();
      peg$silentFails--;
      if (s6 === peg$FAILED) {
        s5 = void 0;
      } else {
        peg$currPos = s5;
        s5 = peg$FAILED;
      }
      if (s5 !== peg$FAILED) {
        if (input.length > peg$currPos) {
          s6 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s6 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c4); }
        }
        if (s6 !== peg$FAILED) {
          s5 = [s5, s6];
          s4 = s5;
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
      while (s4 !== peg$FAILED) {
        s3.push(s4);
        s4 = peg$currPos;
        s5 = peg$currPos;
        peg$silentFails++;
        s6 = peg$parsenl();
        peg$silentFails--;
        if (s6 === peg$FAILED) {
          s5 = void 0;
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
        if (s5 !== peg$FAILED) {
          if (input.length > peg$currPos) {
            s6 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s6 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c4); }
          }
          if (s6 !== peg$FAILED) {
            s5 = [s5, s6];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
      }
      if (s3 !== peg$FAILED) {
        s2 = input.substring(s2, peg$currPos);
      } else {
        s2 = s3;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parsenl();
        if (s3 === peg$FAILED) {
          s3 = peg$parseEOF();
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c220(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "comment",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "comment",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseLinebreak() {
    var s0, s1, s2, s3,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Linebreak",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseLinebreak_p();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c40(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Linebreak",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Linebreak",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseLinebreak_p() {
    var s0, s1, s2, s3, s4,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Linebreak_p",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 9) === peg$c221) {
        s2 = peg$c221;
        peg$currPos += 9;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c222); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseArgumentList();
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c223(s2, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseescape();
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$parseescape();
        if (s3 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 42) {
            s4 = peg$c65;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c66); }
          }
          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 === peg$FAILED) {
          s2 = peg$parseescape();
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parseArgumentList();
          if (s3 === peg$FAILED) {
            s3 = null;
          }
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c223(s2, s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseescape();
        if (s1 !== peg$FAILED) {
          if (input.substr(peg$currPos, 8) === peg$c224) {
            s2 = peg$c224;
            peg$currPos += 8;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c225); }
          }
          if (s2 === peg$FAILED) {
            if (input.substr(peg$currPos, 7) === peg$c226) {
              s2 = peg$c226;
              peg$currPos += 7;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c227); }
            }
          }
          if (s2 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c228(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Linebreak_p",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Linebreak_p",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseSoftbreak() {
    var s0, s1, s2, s3, s4, s5, s6, s7,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Softbreak",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$currPos;
    s3 = peg$currPos;
    peg$silentFails++;
    s4 = peg$parsebreak();
    peg$silentFails--;
    if (s4 === peg$FAILED) {
      s3 = void 0;
    } else {
      peg$currPos = s3;
      s3 = peg$FAILED;
    }
    if (s3 !== peg$FAILED) {
      s4 = peg$parsesp();
      if (s4 === peg$FAILED) {
        s4 = peg$parseskip_comment();
      }
      if (s4 !== peg$FAILED) {
        s3 = [s3, s4];
        s2 = s3;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$currPos;
      s3 = peg$currPos;
      peg$silentFails++;
      s4 = peg$parsebreak();
      peg$silentFails--;
      if (s4 === peg$FAILED) {
        s3 = void 0;
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$parsesp();
        if (s4 === peg$FAILED) {
          s4 = peg$parseskip_comment();
        }
        if (s4 !== peg$FAILED) {
          s3 = [s3, s4];
          s2 = s3;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$silentFails++;
      s3 = peg$parsebreak();
      peg$silentFails--;
      if (s3 === peg$FAILED) {
        s2 = void 0;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parsenl();
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$currPos;
          s6 = peg$currPos;
          peg$silentFails++;
          s7 = peg$parsebreak();
          peg$silentFails--;
          if (s7 === peg$FAILED) {
            s6 = void 0;
          } else {
            peg$currPos = s6;
            s6 = peg$FAILED;
          }
          if (s6 !== peg$FAILED) {
            s7 = peg$parsesp();
            if (s7 === peg$FAILED) {
              s7 = peg$parseskip_comment();
            }
            if (s7 !== peg$FAILED) {
              s6 = [s6, s7];
              s5 = s6;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = peg$currPos;
            s6 = peg$currPos;
            peg$silentFails++;
            s7 = peg$parsebreak();
            peg$silentFails--;
            if (s7 === peg$FAILED) {
              s6 = void 0;
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            if (s6 !== peg$FAILED) {
              s7 = peg$parsesp();
              if (s7 === peg$FAILED) {
                s7 = peg$parseskip_comment();
              }
              if (s7 !== peg$FAILED) {
                s6 = [s6, s7];
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c229();
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Softbreak",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Softbreak",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseSpace() {
    var s0, s1, s2, s3, s4,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "Space",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$currPos;
    s3 = peg$currPos;
    peg$silentFails++;
    s4 = peg$parsebreak();
    peg$silentFails--;
    if (s4 === peg$FAILED) {
      s3 = void 0;
    } else {
      peg$currPos = s3;
      s3 = peg$FAILED;
    }
    if (s3 !== peg$FAILED) {
      s4 = peg$parsenl();
      if (s4 === peg$FAILED) {
        s4 = peg$parsesp();
      }
      if (s4 !== peg$FAILED) {
        s3 = [s3, s4];
        s2 = s3;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$currPos;
        s3 = peg$currPos;
        peg$silentFails++;
        s4 = peg$parsebreak();
        peg$silentFails--;
        if (s4 === peg$FAILED) {
          s3 = void 0;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parsenl();
          if (s4 === peg$FAILED) {
            s4 = peg$parsesp();
          }
          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c230();
    }
    s0 = s1;

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "Space",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "Space",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsewhitespace() {
    var s0, s1, s2, s3, s4, s5,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "whitespace",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parsenl();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parsesp();
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parsesp();
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parsesp();
      if (s2 !== peg$FAILED) {
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          s2 = peg$parsesp();
        }
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parsenl();
        if (s2 !== peg$FAILED) {
          s3 = [];
          s4 = peg$parsesp();
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$parsesp();
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$currPos;
            peg$silentFails++;
            s5 = peg$parsenl();
            peg$silentFails--;
            if (s5 === peg$FAILED) {
              s4 = void 0;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
            if (s4 !== peg$FAILED) {
              s1 = [s1, s2, s3, s4];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = [];
        s1 = peg$parsesp();
        if (s1 !== peg$FAILED) {
          while (s1 !== peg$FAILED) {
            s0.push(s1);
            s1 = peg$parsesp();
          }
        } else {
          s0 = peg$FAILED;
        }
      }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "whitespace",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "whitespace",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsenl() {
    var s0,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "nl",
      location: peg$computeLocation(startPos, startPos)
    });

    if (input.charCodeAt(peg$currPos) === 10) {
      s0 = peg$c231;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c232); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c233) {
        s0 = peg$c233;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c234); }
      }
      if (s0 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 13) {
          s0 = peg$c235;
          peg$currPos++;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c236); }
        }
        if (s0 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 8232) {
            s0 = peg$c237;
            peg$currPos++;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c238); }
          }
          if (s0 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 8233) {
              s0 = peg$c239;
              peg$currPos++;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c240); }
            }
          }
        }
      }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "nl",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "nl",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsesp() {
    var s0,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "sp",
      location: peg$computeLocation(startPos, startPos)
    });

    if (peg$c241.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c242); }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "sp",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "sp",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseskip_space() {
    var s0, s1, s2, s3,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "skip_space",
      location: peg$computeLocation(startPos, startPos)
    });

    peg$silentFails++;
    s0 = [];
    s1 = peg$currPos;
    s2 = peg$currPos;
    peg$silentFails++;
    s3 = peg$parsebreak();
    peg$silentFails--;
    if (s3 === peg$FAILED) {
      s2 = void 0;
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      s3 = peg$parsenl();
      if (s3 === peg$FAILED) {
        s3 = peg$parsesp();
        if (s3 === peg$FAILED) {
          s3 = peg$parseskip_comment();
        }
      }
      if (s3 !== peg$FAILED) {
        s2 = [s2, s3];
        s1 = s2;
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    while (s1 !== peg$FAILED) {
      s0.push(s1);
      s1 = peg$currPos;
      s2 = peg$currPos;
      peg$silentFails++;
      s3 = peg$parsebreak();
      peg$silentFails--;
      if (s3 === peg$FAILED) {
        s2 = void 0;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parsenl();
        if (s3 === peg$FAILED) {
          s3 = peg$parsesp();
          if (s3 === peg$FAILED) {
            s3 = peg$parseskip_comment();
          }
        }
        if (s3 !== peg$FAILED) {
          s2 = [s2, s3];
          s1 = s2;
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c243); }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "skip_space",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "skip_space",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseskip_comment() {
    var s0, s1,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "skip_comment",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = peg$parsecomment();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c244(s1);
    }
    s0 = s1;

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "skip_comment",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "skip_comment",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseskip_all_space() {
    var s0, s1, s2,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "skip_all_space",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parsenl();
    if (s2 === peg$FAILED) {
      s2 = peg$parsesp();
      if (s2 === peg$FAILED) {
        s2 = peg$parseskip_comment();
      }
    }
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$parsenl();
      if (s2 === peg$FAILED) {
        s2 = peg$parsesp();
        if (s2 === peg$FAILED) {
          s2 = peg$parseskip_comment();
        }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c245();
    }
    s0 = s1;

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "skip_all_space",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "skip_all_space",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parsebreak() {
    var s0, s1, s2, s3, s4, s5, s6, s7,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "break",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$currPos;
    s3 = peg$parseskip_all_space();
    if (s3 !== peg$FAILED) {
      s4 = peg$parseescape();
      if (s4 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c246) {
          s5 = peg$c246;
          peg$currPos += 3;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c247); }
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$currPos;
          peg$silentFails++;
          s7 = peg$parsechar();
          peg$silentFails--;
          if (s7 === peg$FAILED) {
            s6 = void 0;
          } else {
            peg$currPos = s6;
            s6 = peg$FAILED;
          }
          if (s6 !== peg$FAILED) {
            s7 = peg$parseskip_all_space();
            if (s7 !== peg$FAILED) {
              s3 = [s3, s4, s5, s6, s7];
              s2 = s3;
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$currPos;
        s3 = peg$parseskip_all_space();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseescape();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 3) === peg$c246) {
              s5 = peg$c246;
              peg$currPos += 3;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c247); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$currPos;
              peg$silentFails++;
              s7 = peg$parsechar();
              peg$silentFails--;
              if (s7 === peg$FAILED) {
                s6 = void 0;
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parseskip_all_space();
                if (s7 !== peg$FAILED) {
                  s3 = [s3, s4, s5, s6, s7];
                  s2 = s3;
                } else {
                  peg$currPos = s2;
                  s2 = peg$FAILED;
                }
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c248();
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parsesp();
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parsesp();
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$parsenl();
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$parseskip_comment();
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = peg$parseskip_comment();
          }
          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 === peg$FAILED) {
          s2 = [];
          s3 = peg$parseskip_comment();
          if (s3 !== peg$FAILED) {
            while (s3 !== peg$FAILED) {
              s2.push(s3);
              s3 = peg$parseskip_comment();
            }
          } else {
            s2 = peg$FAILED;
          }
        }
        if (s2 !== peg$FAILED) {
          s3 = [];
          s4 = peg$currPos;
          s5 = [];
          s6 = peg$parsesp();
          while (s6 !== peg$FAILED) {
            s5.push(s6);
            s6 = peg$parsesp();
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parsenl();
            if (s6 !== peg$FAILED) {
              s5 = [s5, s6];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            while (s4 !== peg$FAILED) {
              s3.push(s4);
              s4 = peg$currPos;
              s5 = [];
              s6 = peg$parsesp();
              while (s6 !== peg$FAILED) {
                s5.push(s6);
                s6 = peg$parsesp();
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parsenl();
                if (s6 !== peg$FAILED) {
                  s5 = [s5, s6];
                  s4 = s5;
                } else {
                  peg$currPos = s4;
                  s4 = peg$FAILED;
                }
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            }
          } else {
            s3 = peg$FAILED;
          }
          if (s3 !== peg$FAILED) {
            s4 = [];
            s5 = peg$parsesp();
            if (s5 === peg$FAILED) {
              s5 = peg$parsenl();
              if (s5 === peg$FAILED) {
                s5 = peg$parseskip_comment();
              }
            }
            while (s5 !== peg$FAILED) {
              s4.push(s5);
              s5 = peg$parsesp();
              if (s5 === peg$FAILED) {
                s5 = peg$parsenl();
                if (s5 === peg$FAILED) {
                  s5 = peg$parseskip_comment();
                }
              }
            }
            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c248();
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "break",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "break",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }

  function peg$parseEOF() {
    var s0, s1,
        startPos = peg$currPos;

    peg$tracer.trace({
      type:     "rule.enter",
      rule:     "EOF",
      location: peg$computeLocation(startPos, startPos)
    });

    s0 = peg$currPos;
    peg$silentFails++;
    if (input.length > peg$currPos) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c4); }
    }
    peg$silentFails--;
    if (s1 === peg$FAILED) {
      s0 = void 0;
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    if (s0 !== peg$FAILED) {
      peg$tracer.trace({
        type:   "rule.match",
        rule:   "EOF",
        result: s0,
        location: peg$computeLocation(startPos, peg$currPos)
      });
    } else {
      peg$tracer.trace({
        type: "rule.fail",
        rule: "EOF",
        location: peg$computeLocation(startPos, startPos)
      });
    }

    return s0;
  }


    const timeKeeper = options.timeout;
    const commentMap = options.enableComment ? new Map() : undefined;


  peg$result = peg$startRuleFunction();

  if (peg$result !== peg$FAILED && peg$currPos === input.length) {
    return peg$result;
  } else {
    if (peg$result !== peg$FAILED && peg$currPos < input.length) {
      peg$fail(peg$endExpectation());
    }

    throw peg$buildStructuredError(
      peg$maxFailExpected,
      peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
      peg$maxFailPos < input.length
        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
    );
  }
}

module.exports = {
  SyntaxError:   peg$SyntaxError,
  DefaultTracer: peg$DefaultTracer,
  parse:         peg$parse
};
